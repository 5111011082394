import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';
import Fire from '../../../../icons/Fire';
import BrandItem from '../BrandItem';
import NotContent from '../../../../icons/NotContent';
import BrandItemSkeleton from '../BrandItemSkeleton';

import isElementIntoViewPort from '../../../../utils/isElementIntoViewPort';
import { BrandContext, BrandContextProvider } from '../../lib/Brand/BrandContext';
import { WhiteListContext, WhiteListContextProvider } from '../../lib/Brand/WhiteListContext';
import {
    FiltersContext,
    FiltersContextProvider,
    SORT_BY_OPTIONS_ARR,
} from '../../lib/Brand/FiltersContext';

export const Brands = ({ setCount }) => {
    const [fullText, setFullText] = React.useState(false);
    const [filter, setFilter] = React.useState('random_score');
    const [selectedCategory, setSelectedCategory] = React.useState('all');
    const [searchText, setSearchText] = React.useState();
    const pages = useSelector((state) => state.pages.entities);
    const [description, setDescription] = React.useState(
        `Цифровые сертификаты - это новый прорыв в мире современных технологий. Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям. Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов. Коллекционирование таких сертификатов становится новым трендом, ведь собранную коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`,
    );
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const showText = (e) => {
        e.preventDefault();
        setFullText((prev) => !prev);
    };
    const [isLoading, setIsLoadingCollection] = React.useState(true);

    const {
        state: { collectionTokens, collectionTokensCount: count },
        actions: { onNextPageCollectionsTokensHandler, handlePage, handleFilter, handleSearch },

        isTokensLoading,
        isFetchingLoading,
    } = React.useContext(BrandContext);

    const { isWhitelistApplicationLoading } = React.useContext(WhiteListContext);

    React.useEffect(() => {
        const queryParams = new URLSearchParams(search);
        const cat = queryParams.get('cat');
        setSelectedCategory(cat && cat !== '' ? cat : 'all');
        const term = queryParams.get('search') || '';
        setSearchText(term);
    }, [pathname, navigate]);

    const containerRef = React.useRef(null);
    const firstUndrowenItemIdx = React.useRef(-1);

    const isDataLoading = React.useMemo(() => {
        return !collectionTokens || isWhitelistApplicationLoading || isTokensLoading;
    }, [collectionTokens, isWhitelistApplicationLoading, isTokensLoading, isFetchingLoading]);

    React.useEffect(() => {
        setIsLoadingCollection(isDataLoading);
    }, [isDataLoading]);

    const onPageScrollHandler = React.useCallback(() => {
        if (containerRef.current && firstUndrowenItemIdx.current > -1) {
            const elem = Array.from(containerRef.current.childNodes)[firstUndrowenItemIdx.current];

            if (!isDataLoading && elem && isElementIntoViewPort(elem)) {
                onNextPageCollectionsTokensHandler();
                firstUndrowenItemIdx.current = -1;
            }
        }
    }, [isDataLoading]);

    React.useEffect(() => {
        window.addEventListener('scroll', onPageScrollHandler);

        return () => {
            window.removeEventListener('scroll', onPageScrollHandler);
        };
    }, [isDataLoading]);

    React.useEffect(() => {
        !isTokensLoading && setCount(count);
    }, [count]);

    React.useEffect(() => {
        handlePage(selectedCategory);

        setDescription(
            selectedCategory == 'all'
                ? `Цифровые сертификаты - это новый прорыв в мире современных технологий. Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям. Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов. Коллекционирование таких сертификатов становится новым трендом, ведь собранную коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`
                : pages[selectedCategory].description,
        );
    }, [selectedCategory]);

    React.useEffect(() => {
        handleSearch(searchText);
    }, [searchText]);

    React.useEffect(() => {
        handleFilter(filter);
    }, [filter]);

    return (
        <>
            <div className={styles.brandSubTabs}>
                {isLoading ? (
                    <>
                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />
                    </>
                ) : (
                    <>
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: selectedCategory === 'all',
                            })}
                            onClick={() => {
                                setSelectedCategory('all');
                            }}
                        >
                            Все
                        </button>
                        {Object.values(pages).map((page) => (
                            <button
                                className={cn(styles.brandSubTab, {
                                    [styles.active]: selectedCategory === page.id,
                                })}
                                onClick={() => {
                                    setSelectedCategory(page.id);
                                }}
                            >
                                {page.name}
                            </button>
                        ))}
                    </>
                )}
            </div>
            {isLoading ? (
                <div className={styles.brandsText}>
                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />
                </div>
            ) : (
                <div className={styles.brandsText}>
                    <p>
                        {description && description.length > 150 && !fullText
                            ? `${description.slice(0, 150)}...`
                            : description}
                    </p>

                    {description && description.length > 150 && (
                        <a href="#" className={styles.brandsTextShow} onClick={showText}>
                            {fullText ? 'Свернуть' : 'Развернуть'}
                        </a>
                    )}
                </div>
            )}
            <div className={styles.brandOuter}>
                <div className={styles.brandSubTabs}>
                    {isLoading ? (
                        <>
                            <Skeleton
                                containerClassName={cn(
                                    styles.brandSubTabSkeletonWrapper,
                                    styles.small,
                                )}
                                className={styles.brandSubTabSkeleton}
                            />

                            <Skeleton
                                containerClassName={styles.brandSubTabSkeletonWrapper}
                                className={styles.brandSubTabSkeleton}
                            />
                        </>
                    ) : (
                        <>
                            <button
                                className={cn(styles.brandSubTab, {
                                    [styles.active]: filter === 'random_score',
                                })}
                                onClick={() => {
                                    setFilter('random_score');
                                }}
                            >
                                Все
                            </button>

                            <button
                                className={cn(styles.brandSubTab, styles.warning, {
                                    [styles.active]: filter === 'owners_count',
                                })}
                                onClick={() => {
                                    setFilter('owners_count');
                                }}
                            >
                                Лидеры продаж
                                <Fire />
                            </button>
                        </>
                    )}
                </div>

                {isLoading ? (
                    <Skeleton
                        containerClassName={styles.tokenValueSkeletonWrapper}
                        className={styles.tokenValueSkeleton}
                    />
                ) : (
                    <p className={styles.tokenValue}>{count} шт.</p>
                )}
            </div>
            <div className={styles.brandsContent} ref={containerRef}>
                {isLoading ? (
                    <>
                        <BrandItemSkeleton />
                        <BrandItemSkeleton />
                        <BrandItemSkeleton />
                        <BrandItemSkeleton />
                        <BrandItemSkeleton />
                        <BrandItemSkeleton />
                        <BrandItemSkeleton />
                        <BrandItemSkeleton />
                    </>
                ) : (
                    <>
                        {collectionTokens.map((brand) => (
                            <BrandItem key={brand.id} brand={brand} />
                        ))}
                        {collectionTokens.length > 0 &&
                            count - collectionTokens.length > 0 &&
                            Array.from(Array(count - collectionTokens.length).keys()).map((idx) => {
                                if (firstUndrowenItemIdx.current === -1) {
                                    firstUndrowenItemIdx.current = idx + collectionTokens.length;
                                }
                                return <BrandItemSkeleton />;
                            })}
                    </>
                )}
            </div>
            ;
            {!isLoading && count === 0 && (
                <div className={styles.notContentWrapper}>
                    <div className={styles.notContent}>
                        <NotContent />

                        <p className={cn(base.title2, styles.notContentTitle)}>
                            Нет информации
                            <br />
                            для отображения
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export const BrandsContext = ({ setCount }) => {
    const { id } = useParams();

    return (
        <FiltersContextProvider>
            <BrandContextProvider id={id}>
                <WhiteListContextProvider>
                    <Brands setCount={setCount} />
                </WhiteListContextProvider>
            </BrandContextProvider>
        </FiltersContextProvider>
    );
};
