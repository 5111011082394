exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CollectionStatusChange_cancelBookApplicationBtn__11QYq {\n    color: white;\n    background: #0082e9;\n    border-radius: 8px;\n    width: 100%;\n    height: 100%;\n    min-width: 100px;\n    max-width: 100%;\n    min-height: 36px;\n}\n\n.CollectionStatusChange_cancelBookApplicationBtn__11QYq:hover {\n    opacity: 0.9;\n}\n", ""]);

// exports
exports.locals = {
	"cancelBookApplicationBtn": "CollectionStatusChange_cancelBookApplicationBtn__11QYq"
};