import React from 'react';

import css from '../Table.module.css';

// type Props = {
//   value?: string;
//   classes?: {
//     cellRoot?: string;
//   };
// };

const StatusCell = (props) => {
    const { value, number, circleColor, classes } = props;

    return (
        <div className={classes.cellRoot}>
            <p className={`stats__item--value ${css.rowIndex}`}>{number}</p>
            <div
                className={`stats__item--status--circle ${css.statusCircle}`}
                style={{ background: circleColor }}
            />
            <span className={css.bodyCellText}>{value || '---'}</span>
        </div>
    );
};

export default React.memo(StatusCell);
