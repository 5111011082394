exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Paginator_paginationToolbar__1iBTT {\n    height: 36px;\n    min-height: 36px;\n    padding: 0;\n    justify-content: flex-start;\n}\n\n.Paginator_paginationSelectLabel__2oDBK {\n    margin: 0;\n    font-family: 'Gilroy', sans-serif;\n    font-weight: 400;\n    color: white;\n    font-size: 16px !important;\n}\n\n.Paginator_paginationSelect__2ieez {\n    font-family: 'Gilroy', sans-serif !important;\n    margin-left: 0;\n    color: white !important;\n    font-weight: 400 !important;\n    line-height: 22px !important;\n    background: rgba(255, 255, 255, 0.1) !important;\n    border-radius: 5px !important;\n    font-size: 16px !important;\n}\n\n.Paginator_paginationSpacer__3Uh3B {\n    width: 0;\n    min-width: 0;\n}\n\n.Paginator_paginationDisplayedRows__PpRoq {\n    font-family: 'Gilroy', sans-serif;\n    color: white;\n    font-size: 16px !important;\n}\n\n.Paginator_paginationSelectRoot__4pQ9r {\n    font-family: 'Gilroy', sans-serif;\n    color: white;\n    font-size: 16px !important;\n    margin-left: 0;\n    margin-right: 40px;\n}\n", ""]);

// exports
exports.locals = {
	"paginationToolbar": "Paginator_paginationToolbar__1iBTT",
	"paginationSelectLabel": "Paginator_paginationSelectLabel__2oDBK",
	"paginationSelect": "Paginator_paginationSelect__2ieez",
	"paginationSpacer": "Paginator_paginationSpacer__3Uh3B",
	"paginationDisplayedRows": "Paginator_paginationDisplayedRows__PpRoq",
	"paginationSelectRoot": "Paginator_paginationSelectRoot__4pQ9r"
};