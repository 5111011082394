import React, { useState, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { cnb } from 'cnbuilder';
import { Skeleton } from '@mui/material';

import TrashIcon from '../../icons/TrashIcon';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';
import { roundInt } from '../../utils/roundInt';
import TokenItem from './UI/TokenItem';
import { WHITE_LIST_APPLICATION_STATUSES } from '../../const/collection/WHITE_LIST_APPLICATION_STATUSES';
import { CustomSelect } from '../../common/CustomSelect';
// import FilterItem from '../../components/FilterItem';
import { FilterItemOldLists } from '../../components/FilterItemOldLists/FilterItemOldLists';
import SortItem from '../../components/SortItem';
import { copyUrl } from '../../functions/utils';
import CollectionSkeleton from './CollectionSkeleton';
import { CollectionContext, CollectionContextProvider } from './lib/CollectionContext';
import { WhiteListContext, WhiteListContextProvider } from './lib/WhiteListContext';
import { COLLECTION_STATUS } from '../../const/collection/COLLECTION_STATUS';
import CollectionAction from './UI/CollectionAction';
import CollectionInfo from '../../common/CollectionInfo';
import InfoBlock from '../../common/InfoBlock';
import FullDescription from '../../common/FullDescription';
import NoItems from '../../common/NoItems';
import isElementIntoViewPort from '../../utils/isElementIntoViewPort';
import { FiltersContext, FiltersContextProvider, SORT_BY_OPTIONS_ARR } from './lib/FiltersContext';

import './index.css';
import styles from './index.module.css';
import { useWindowResize } from '../../hooks/useWindowResize';

const sortList = [
    // { value: 'random', name: 'Случайно', active: true },
    { value: '-created_at', name: 'От новых к старым', active: true },
    { value: 'created_at', name: 'От старых к новым', active: false },
    { value: '-price', name: 'От большей цены', active: false },
    { value: 'price', name: 'От меньшей цены', active: false },
];

const viewOptions = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
};

const Collection = () => {
    const {
        state: { collection, bookedTokensIds, collectionTokens, collectionTokensCount, sortBy: filterSortBy },
        actions: { onNextPageCollectionsTokensHandler, onCollectionTokensRefetch },

        isTokensLoading,
        isFetchingLoading,
        isCollectionLoading,
    } = useContext(CollectionContext);

    const {isDesktop} = useWindowResize()


    const {
        state: { whiteListApplication },
        actions: {
            onGetToWhitelistHandler,
            onBookTokenHandler,
            onDeleteWhiteListRequestHandler,
            onCancelHandler,
        },

        isWhitelistApplicationLoading,
        bookTokenIsLoading,
    } = useContext(WhiteListContext);

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const {
        state: {
            query,
            sortBy,
            activeStatusesFilter,
            activePropertiesFilter,
            activeLevelStatsFilter,
            paid
        },
        actions: {
            setStatusesFilter,
            handleSearchQueryHandler,
            unsetStatusesFilter,
            handleSortByChange,

            setPropertiesFilter,
            unsetPropertiesFilter,
            changePropertiesSearchQuery,
            setLevelStatsFilter,
            unsetLevelStatsFilter,
            changeLevelsStatsSearchQuery,
            onChangePropertiesFilter,
            onChangeStatusesFilter,

            dropAllFilters,
        },
        properties,
        levelStats,
    } = useContext(FiltersContext);

    const [view, setView] = useState(viewOptions.DESKTOP);
    const [filterActive, setFilterActive] = useState(isDesktop ? true : false);
    const [sortActive, setSortActive] = useState(false);
    const [social, setSocial] = useState(false);
    const [headerOffsetTop, setHeaderOffsetTop] = useState(0);
    const containerRef = useRef(null);
    const filtersTopRef = useRef(null);
    const filtersBottomRef = useRef(null);
    const firstUndrowenItemIdx = useRef(-1);

    useEffect(() => {
        const clickHandler = (e) => {
            const div = document.getElementById('collection__social__modal');
            const withinBoundaries = e.composedPath().includes(div);

            if (!withinBoundaries) {
                setSocial(false);
            }
        };

        document.addEventListener('click', clickHandler);

        return () => {
            document.removeEventListener('click', clickHandler);
        };
    }, []);

    const emptySearch = useCallback(() => {
        handleSearchQueryHandler('');
    }, []);

    const copyUrlPage = () => {
        copyUrl();
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Ссылка скопирована!',
        });
    };

    const activeFiltersArr = useMemo(() => {
        const res = [];

        activeStatusesFilter.forEach((status) => {
            res.push({
                id: status,
                name: status,
                onRemove: () => unsetStatusesFilter(status),
            });
        });

        activePropertiesFilter.forEach((propertie) => {
            res.push({
                id: propertie.id,
                name: propertie.name,
                onRemove: () => unsetPropertiesFilter(propertie.id),
            });
        });

        // activeLevelStatsFilter.forEach((levelStat) => {
        //     res.push({
        //         id: levelStat.id,
        //         name: levelStat.name,
        //         onRemove: () => unsetStatusesFilter(levelStat.id),
        //     });
        // });

        return res;
    }, [activeStatusesFilter, activePropertiesFilter, activeLevelStatsFilter]);

    const isDataLoading = useMemo(() => {
        return (
            !collection ||
            !collectionTokens ||
            isCollectionLoading ||
            isWhitelistApplicationLoading ||
            isCollectionLoading ||
            isTokensLoading
        );
    }, [
        collection,
        collectionTokens,
        isCollectionLoading,
        isWhitelistApplicationLoading,
        isTokensLoading,
        isFetchingLoading,
        isCollectionLoading,
    ]);

    const actionBtn = useMemo(() => {
        if (
            !whiteListApplication ||
            WHITE_LIST_APPLICATION_STATUSES.RED === whiteListApplication.status
        ) {
            return (
                <button
                    className="button collection__get default__hover"
                    onClick={onGetToWhitelistHandler}
                >
                    Get on whitelist
                </button>
            );
        }

        if (
            !whiteListApplication.token_id &&
            WHITE_LIST_APPLICATION_STATUSES.WHITE === whiteListApplication.status &&
            collection.status === COLLECTION_STATUS.BOOK
        ) {
            return (
                <CollectionAction
                    status="Can booking"
                    firstIcon={<TrashIcon className="collection__whiteList--deleteIcon" />}
                    firstAction={onDeleteWhiteListRequestHandler}
                />
            );
        }

        if (
            WHITE_LIST_APPLICATION_STATUSES.WHITE === whiteListApplication.status &&
            whiteListApplication.token_id &&
            collection.status === COLLECTION_STATUS.MINT_1
        ) {
            return (
                <CollectionAction
                    status="Private minting"
                    firstIcon={<TrashIcon className="collection__whiteList--deleteIcon" />}
                    firstAction={onCancelHandler}
                />
            );
        }

        if (
            whiteListApplication.token_id &&
            WHITE_LIST_APPLICATION_STATUSES.WHITE === whiteListApplication.status
        ) {
            return (
                <CollectionAction
                    status="Booked"
                    firstIcon={<TrashIcon className="collection__whiteList--deleteIcon" />}
                    firstAction={onCancelHandler}
                />
            );
        }

        if ([WHITE_LIST_APPLICATION_STATUSES.NEW].includes(whiteListApplication.status)) {
            return (
                <CollectionAction
                    status="Pending"
                    firstIcon={
                        <ModeEditOutlineIcon
                            classes={{ root: 'collection__whiteList--editIcon' }}
                        />
                    }
                    firstAction={onGetToWhitelistHandler}
                    secondIcon={<TrashIcon className="collection__whiteList--deleteIcon" />}
                    secondAction={onDeleteWhiteListRequestHandler}
                />
            );
        }

        if ([WHITE_LIST_APPLICATION_STATUSES.WHITE].includes(whiteListApplication.status)) {
            return (
                <CollectionAction
                    status="Whitelisted"
                    firstIcon={<TrashIcon className="collection__whiteList--deleteIcon" />}
                    firstAction={onDeleteWhiteListRequestHandler}
                />
            );
        }
    }, [whiteListApplication, collection.status]);

    const onPageScrollHandler = useCallback(() => {
        if (containerRef.current && firstUndrowenItemIdx.current > -1) {
            const elem = Array.from(containerRef.current.parentNode.children[0].children)[
                firstUndrowenItemIdx.current
            ];

            if (!isDataLoading && elem && isElementIntoViewPort(elem)) {
                onNextPageCollectionsTokensHandler();
                firstUndrowenItemIdx.current = -1;
            }
        }
    }, [isDataLoading]);

    // useEffect(() => {
    //     window.addEventListener('scroll', onPageScrollHandler);

    //     return () => {
    //         window.removeEventListener('scroll', onPageScrollHandler);
    //     };
    // }, [isDataLoading]);

    useEffect(() => {
        if (filtersTopRef.current && filtersBottomRef.current) {
            const top = filtersTopRef.current.getBoundingClientRect().top;
            const bottom = filtersBottomRef.current.getBoundingClientRect().bottom;

            setHeaderOffsetTop(bottom - top);
        }
    }, [collectionTokensCount, activeFiltersArr, isDataLoading]);

    // if (isDataLoading) {
    //     return <CollectionSkeleton />;
    // }

console.log(collectionTokensCount,'dayn');
    return (
        <>
            <div
                className="collection__header"
                style={{
                    backgroundImage: `url('${collection.banner}')`,
                    backgroundSize: 'cover',
                    objectFit: 'cover',
                    backgroundPosition: 'center',
                }}
            />

            <div className="collection">
                <div className="collection__social--mobile">
                    <a href="#" className="collection__social--share" onClick={copyUrlPage}>
                        <img src="/assets/img/share2.svg" alt="share" />
                    </a>

                    <div
                        className="collection__dots--inner"
                        id="collection__social__modal"
                        onClick={() => setSocial((prev) => !prev)}
                    >
                        <img
                            src="/assets/img/dots.svg"
                            alt="dots"
                            className="collection__social--dots"
                        />

                        {social && (
                            <div className="collection__dots--social">
                                <p className="collection__dots--title">Links</p>

                                {collection.link_yoursite && (
                                    <a
                                        href={collection.link_yoursite}
                                        className="collection__dots--link"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/assets/img/Internet.svg"
                                            alt="opensea"
                                            className="collection__dots--link--icon"
                                        />
                                        Site
                                    </a>
                                )}

                                {collection.link_telegram && (
                                    <a
                                        href={collection.link_telegram}
                                        className="collection__dots--link"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/assets/img/tg.svg"
                                            alt="tg"
                                            className="collection__dots--link--icon"
                                        />
                                        Telegram
                                    </a>
                                )}

                                {collection.link_vk && (
                                    <a
                                        href={collection.link_vk}
                                        className="collection__dots--link"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/assets/img/vk.svg"
                                            alt="medium"
                                            className="collection__dots--link--icon"
                                        />
                                        VK
                                    </a>
                                )}

                                {collection.link_instagram && (
                                    <a
                                        href={collection.link_instagram}
                                        className="collection__dots--link"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/assets/img/insta.svg"
                                            alt="insta"
                                            className="collection__dots--link--icon"
                                        />
                                        Instagram
                                    </a>
                                )}

                                {collection.link_youtube && (
                                    <a
                                        href={collection.link_youtube}
                                        className="collection__dots--link"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/assets/img/youtube.svg"
                                            alt="Twitter"
                                            className="collection__dots--link--icon"
                                        />
                                        Youtube
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="container">
                    <div className="collection__inner">
                        <div className="collection__chain">
                            <img
                                src="/assets/img/eth-black.svg"
                                alt="icon"
                                className="collection__chain--icon"
                            />
                        </div>

                        <div className="collection__avatar--inner">
                            <img
                                src={collection.logo}
                                alt="avatar"
                                className="collection__avatar"
                            />
                        </div>

                        <div className="collection__link--inner">
                            <h2 className="title collection__title">{collection.name}</h2>

                            <div className="collection__social--inner">

                                {collection.link_yoursite && (
                                    <a
                                        href={collection.link_yoursite}
                                        className="collection__social--link"
                                    >
                                        <img
                                            src="/assets/img/Internet.svg"
                                            alt="opensea"
                                            className="collection__social--icon"
                                        />
                                    </a>
                                )}

                                {collection.link_telegram && (
                                    <a
                                        href={collection.link_telegram}
                                        className="collection__social--link"
                                    >
                                        <img
                                            src="/assets/img/tg.svg"
                                            alt="vk"
                                            className="collection__social--icon"
                                        />
                                    </a>
                                )}

                                {collection.link_vk && (
                                    <a
                                        href={collection.link_vk}
                                        className="collection__social--link"
                                    >
                                        <img
                                            src="/assets/img/vk.svg"
                                            alt="vk"
                                            className="collection__social--icon"
                                        />
                                    </a>
                                )}

                                {collection.link_instagram && (
                                    <a
                                        href={collection.link_instagram}
                                        className="collection__social--link"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/assets/img/insta.svg"
                                            alt="instagram"
                                            className="collection__social--icon"
                                        />
                                    </a>
                                )}

                                {collection.link_youtube && (
                                    <a
                                        href={collection.link_youtube}
                                        className="collection__social--link"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/assets/img/youtube.svg"
                                            alt="twitter"
                                            className="collection__social--icon"
                                        />
                                    </a>
                                )}

                                <div className="collection__social--link" onClick={copyUrlPage}>
                                    <img
                                        src="/assets/img/share2.svg"
                                        alt="share"
                                        className="collection__social--icon"
                                    />
                                </div>
                            </div>
                        </div>

                        <InfoBlock
                            data={[
                                {
                                    title: 'объём продаж',
                                    value: `${Math.floor(collection.volume_troded_count/1000)} RUB`,
                                },
                                {
                                    title: 'мин. цена',
                                    value: `${Math.floor(collection.floor_price_count/100) } RUB `,
                                },
                                {
                                    title: 'всего',
                                    value: collection.items_count,
                                },
                                {
                                    title: 'продано',
                                    value: collection.owners_count,
                                },
                            ]}
                        />

                        <FullDescription description={collection.description} url={collection.offer} isOffer={true}/>

                        <div className="collection__wrapper">
                            <div ref={filtersTopRef} />
                            <div className="collection__filter--content">
                                <button
                                    className="button collection__filter--button"
                                    onClick={() => setFilterActive((prev) => !prev)}
                                >
                                    <img
                                        src="/assets/img/filter.svg"
                                        alt="filter"
                                        className="collection__filter--icon"
                                    />

                                    <img
                                        src="/assets/img/filter2.svg"
                                        alt="filter"
                                        className="collection__filter--icon mobile"
                                    />

                                    <p className="collection__filter--button--text">Фильтры</p>
                                </button>

                                <button
                                    className="button collection__sort--button"
                                    onClick={() => setSortActive((prev) => !prev)}
                                >
                                    <img
                                        src="/assets/img/sort.svg"
                                        alt="sort"
                                        className="collection__filter--icon mobile"
                                    />

                                    <p className="collection__sort--button--text">Сортировать</p>
                                </button>

                                <div className="collection__search--inner">
                                    <input
                                        type="text"
                                        className="input header__search"
                                        placeholder="Поиск по названию сертификата"
                                        value={query}
                                        onChange={({ target: { value } }) =>
                                            handleSearchQueryHandler(value)
                                        }
                                    />

                                    <img
                                        src="/assets/img/search.svg"
                                        alt="search"
                                        className="header__search--icon"
                                    />

                                    {query && (
                                        <img
                                            src="/assets/img/cross2.svg"
                                            alt="cross"
                                            className="header__search--remove"
                                            onClick={emptySearch}
                                        />
                                    )}
                                </div>

                                <div className="collection__filter--order">
                                    <CustomSelect
                                        optionsList={SORT_BY_OPTIONS_ARR}
                                        value={sortBy}
                                        placeholder="Выбрать фильтер"
                                        onChange={handleSortByChange}
                                    />
                                </div>

                                <div className="collection__filter--view">
                                    <button
                                        className={`button collection__filter--view--item${
                                            view === viewOptions.DESKTOP ? ' active' : ''
                                        }`}
                                        onClick={() => setView(viewOptions.DESKTOP)}
                                    >
                                        <img
                                            src="/assets/img/view2.svg"
                                            alt="view"
                                            className="collection__filter--view--icon"
                                        />
                                    </button>

                                    <button
                                        className={`button collection__filter--view--item${
                                            view === viewOptions.MOBILE ? ' active' : ''
                                        }`}
                                        onClick={() => setView(viewOptions.MOBILE)}
                                    >
                                        <img
                                            src="/assets/img/view1.svg"
                                            alt="view"
                                            className="collection__filter--view--icon"
                                        />
                                    </button>
                                </div>
                                {/*{actionBtn}*/}
                            </div>

                            <div className="collection__filters">
                                {filterActive && (
                                    <div className="collection__filter--box">
                                        <div className="collection__filter--title--box">
                                            <p className="collection__filter--title">Фильтры</p>

                                            <img
                                                src="/assets/img/cross2.svg"
                                                alt="cross"
                                                className="collection__filter--title--cross"
                                                onClick={() => {
                                                    setFilterActive((prev) => !prev)
                                                }}
                                            />
                                        </div>

                                        <FilterItemOldLists
                                            title="Состояние"
                                            elements={[{text:'Продано'}, {text: 'В продаже'}, {text: 'Все'}]}
                                            checkedItemsIds={paid === null ? ['Все'] : paid ? ['Продано'] : ['В продаже']}
                                            onChange={onChangeStatusesFilter}
                                            isOpen
                                        />

                                        <FilterItemOldLists
                                            title="Свойства"
                                            elements={properties}
                                            checkedItemsIds={activePropertiesFilter.map(
                                                (el) => el.id,
                                            )}
                                            onChange={onChangePropertiesFilter}
                                            isOpen
                                        />
                                        {/* Its hidden temporary since havent made yet */}
                                        {/* <FilterItem
                                            title="Levels and Stats"
                                            elements={levelStats}
                                            checkedItemsIds={activeLevelStatsFilter.map(
                                                (el) => el.id,
                                            )}
                                            onCheckItem={setLevelStatsFilter}
                                            onRemoveItem={unsetLevelStatsFilter}
                                            handleChangeSearchQuery={changeLevelsStatsSearchQuery}
                                            filter
                                        /> */}

                                        <div className="collection__filter--buttons">
                                            <button
                                                onClick={dropAllFilters}
                                                className="button collection__filter--button--filter"
                                            >
                                                Очистить всё
                                            </button>

                                            <button className="button collection__filter--button--filter blue__button" onClick={() => setFilterActive((prev) => !prev)}>
                                                Готово
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {sortActive && (
                                    <div className="collection__sort--box">
                                        <div className="collection__filter--title--box">
                                            <p className="collection__filter--title">Сортировать по</p>

                                            <img
                                                src="/assets/img/cross2.svg"
                                                alt="cross"
                                                className="collection__filter--title--cross"
                                                onClick={() => setSortActive((prev) => !prev)}
                                            />
                                        </div>

                                        <div className="collection__sort--content">
                                    
                                            {sortList.map((data, id) => (
                                                <SortItem
                                                    key={id}
                                                    text={data.name}
                                                    id={`${data.value}_${id}`}
                                                    onChange={() => handleSortByChange(data.value)}
                                                    checked={data.value === filterSortBy}
                                                />
                                            ))}
                                        </div>

                                        <div className="collection__filter--buttons">
                                            <button className="button collection__sort--button--filter blue__button" onClick={() => setSortActive((prev) => !prev)}>
                                                Готово
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`collection__filters--preitems ${
                                        filterActive ? 'collection__filters--preitems--active' : ''
                                    }`}
                                >
                                    <CollectionInfo
                                        refreshCallback={onCollectionTokensRefetch}
                                        tokensCount={`${collectionTokensCount} шт.`}
                                    />

                                    {activeFiltersArr.length > 0 && (
                                        <div className="collection__filter--active">
                                            <div className="collection__filter--active--content">
                                                {activeFiltersArr.map(({ id, name, onRemove }) => (
                                                    <button
                                                        key={id}
                                                        className="button collection__filter--active--item"
                                                    >
                                                        <p className="collection__filter--active--item--text">
                                                            {name}
                                                        </p>

                                                        <img
                                                            src="/assets/img/cross2.svg"
                                                            alt="cross"
                                                            className="collection__filter--active--item--delete"
                                                            onClick={onRemove}
                                                        />
                                                    </button>
                                                ))}
                                            </div>

                                            <button
                                                className="button collection__filter--clear"
                                                onClick={dropAllFilters}
                                            >
                                                Очистить всё
                                            </button>
                                        </div>
                                    )}
                                    <div
                                      className={styles.collection_items_list}
                                    >
                                        {filterActive && <div className="active-box-plug" />}
                                        <div className={styles.items_wrapper}>
                                            {!isTokensLoading &&
                                              !isFetchingLoading &&
                                              (!collectionTokens || !collectionTokens.length) && (
                                                <NoItems />
                                              )}

                                            {collectionTokens && collectionTokens.length > 0 && (
                                              <div
                                                ref={containerRef}
                                                className={cnb(styles.items_container, {
                                                    [styles.items_container_mobile]:
                                                    view === viewOptions.MOBILE,
                                                })}
                                              >
                                                  {collectionTokens.map((t) => (
                                                    <TokenItem
                                                      key={t.id}
                                                      token={t}
                                                      bookTokenInProccessing={bookTokenIsLoading}
                                                      onBookTokenHandler={onBookTokenHandler}
                                                      isAvailableForBooking={
                                                        bookedTokensIds.every(
                                                          (bookedId) => bookedId !== t.id,
                                                        ) &&
                                                        whiteListApplication &&
                                                        whiteListApplication.status ===
                                                        WHITE_LIST_APPLICATION_STATUSES.WHITE &&
                                                        !whiteListApplication.token_id
                                                      }
                                                      bookedTokenId={
                                                        whiteListApplication &&
                                                        whiteListApplication.token_id
                                                      }
                                                      canBeMinting={
                                                        whiteListApplication &&
                                                        WHITE_LIST_APPLICATION_STATUSES.WHITE ===
                                                        whiteListApplication.status &&
                                                        whiteListApplication.token_id &&
                                                        collection.status ===
                                                        COLLECTION_STATUS.MINT_1 &&
                                                        t.id === whiteListApplication.token_id
                                                      }
                                                    />
                                                  ))}
                                                  {collectionTokensCount - collectionTokens.length > 0 &&
                                                    Array.from(
                                                      Array(
                                                        collectionTokensCount -
                                                        collectionTokens.length,
                                                      ).keys(),
                                                    ).map((idx) => {
                                                        if (firstUndrowenItemIdx.current === -1) {
                                                            firstUndrowenItemIdx.current =
                                                              idx + collectionTokens.length;
                                                        }

                                                        return (
                                                          <div
                                                            key={idx}
                                                            className={styles.collection_item}
                                                          >
                                                              <Skeleton
                                                                sx={{ bgcolor: 'grey.900' }}
                                                                variant="rectangular"
                                                                className="skeleton"
                                                                animation="wave"
                                                              />
                                                          </div>
                                                        );
                                                    })}
                                              </div>
                                            )}
                                        </div>
                                    </div>
                                    <div ref={filtersBottomRef} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const CollectionPrefetch = () => {
    const { id } = useParams();

    return (
        <FiltersContextProvider>
            <CollectionContextProvider id={id}>
                <WhiteListContextProvider>
                    <Collection />
                </WhiteListContextProvider>
            </CollectionContextProvider>
        </FiltersContextProvider>
    );
};

export default CollectionPrefetch;
