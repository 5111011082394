import React, { useState, useRef, useEffect, useCallback } from 'react';
import './index.css';
import { CustomSelect } from '../../common/CustomSelect';
import { Link, useParams } from 'react-router-dom';
import { useLazyGetAccountsListFilteredQuery } from '../../redux/api/handleService';
import {
    createAdmin,
    editRole,
    fetchCollections,
    fetchUserInfoById,
    getRoleNameByStringTitle,
    handleChangeDeleteRolesList,
    handleSelectId,
} from './utils';
import { UsersAccess } from './userAccess';
import { selectVariants } from './utils';
import { SelectMenu } from './selectMenu';

const CreateAdmin = () => {
    const { roleId } = useParams();
    const [userData, setUserData] = useState(null);
    const isRendered = useRef(true);
    const [brands, setBrands] = React.useState([]);
    const [collections, setCollections] = React.useState([]);
    const [name, setName] = useState('');
    const [wallet, setWallet] = useState('');
    const [description, setDescription] = useState('');
    const [reqStatus, setReqStatus] = useState({
        text: '',
        status: null,
    });
    const [accessId, setAccessId] = useState({
        resource_id: '',
        sub_resource_id: '',
    });
    const [getBrands] = useLazyGetAccountsListFilteredQuery();
    const [isRefetch, setIsRefetch] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [rolesDeleteList, setRolesDeleteList] = useState([]);
    const [status, setStatus] = useState(15);
    
    useEffect(() => {
        if (roleId) {
            setIsEditMode(true);
            fetchUserInfoById(setUserData, roleId,setStatus);
           
        }
    }, [roleId]);

    useEffect(() => {
        if (isRefetch === true) {
            fetchCollections(accessId, setCollections);
            setIsRefetch(false);
        }
    }, [accessId, isRefetch]);

    const handleChangeStatus = (el) => {
        setStatus(el);
    };
    const handleCreateAdmin = useCallback(() => {
        if (isEditMode === false) {
            createAdmin(setReqStatus, wallet, description, status, accessId);
        }
        if (isEditMode === true) {
            editRole(roleId, accessId, rolesDeleteList, status, setReqStatus);
        }
    }, [roleId, accessId, rolesDeleteList, status, wallet, description, accessId]);

    useEffect(() => {
        if (isRendered.current === true) {
            getBrands({
                query: name,
            })
                .unwrap()
                .then((result) => {
                    if (result && result.results) {
                        setBrands(
                            result.results.map((brand) => {
                                return { value: brand.id, name: brand.name, state: false };
                            }),
                        );
                    }
                });
            isRendered.current = false;
        }
    }, [isRendered.current]);

    const handleSetBrandId = (brand, type) => {
        setIsRefetch(true);
        handleSelectId(setAccessId, setBrands, brand, type);
    };

    const handleSetCollectionId = (collection, type) => {
        handleSelectId(setAccessId, setCollections, collection, type);
    };

    const handleRemoveAcces = useCallback((payload, isDelete) => {
        handleChangeDeleteRolesList(setRolesDeleteList, payload, isDelete);
    }, []);

    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <div className="title__inner">
                        <Link to="../admins">
                            <img src="/assets/img/back-arrow.svg" alt="arrow" />
                        </Link>

                        <h2 className="title collection__title">
                            {isEditMode ? 'Изменить доступы' : 'Создать администратора/модератора'}
                        </h2>
                    </div>
                    <div className="collection__content">
                        <div className={`checkbox__filter`}>
                            <SelectMenu
                                data={brands}
                                title={'brands'}
                                setOpen={() => {}}
                                onChange={handleSetBrandId}
                            />

                            <SelectMenu
                                data={collections}
                                title={'collections'}
                                setOpen={() => {}}
                                onChange={handleSetCollectionId}
                            />
                        </div>
                        <div>
                            {isEditMode && userData && userData.access && (
                                <div className="userAccess">
                                    <h3>Доступы пользователя</h3>
                                    <UsersAccess
                                        user={userData}
                                        rolesDeleteList={rolesDeleteList}
                                        handleRemoveAcces={handleRemoveAcces}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="collection__content--preitems">
                            <div className="service__content">
                                <div className="service__item flexstart">
                                    <p className="service__item--title">Статус пользователя</p>

                                    <div className="service__item--content">
                                        <CustomSelect
                                            optionsList={selectVariants}
                                            value={status}
                                            placeholder="Collection administrator"
                                            onChange={handleChangeStatus}
                                        />
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">User name</p>
                                    <div className="service__item--content">
                                        {isEditMode ? (
                                            <input
                                                className="input service__item--input"
                                                value={
                                                    userData &&
                                                    userData.user_profile &&
                                                    userData.user_profile.username
                                                }
                                                disabled={true}
                                                placeholder="User name"
                                                // onChange={(e) => setName(e.target.value)}
                                            />
                                        ) : (
                                            <input
                                                className="input service__item--input"
                                                value={name}
                                                placeholder="User name"
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">Wallet</p>
                                    <div className="service__item--content">
                                        {isEditMode ? (
                                            <input
                                                className="input service__item--input"
                                                value={
                                                    userData &&
                                                    userData.user_profile &&
                                                    userData.user_profile.public_address
                                                }
                                                //   onChange={(e) => setWallet(e.target.value)}
                                                disabled={true}
                                                placeholder="Wallet address"
                                            />
                                        ) : (
                                            <input
                                                className="input service__item--input"
                                                value={wallet}
                                                onChange={(e) => setWallet(e.target.value)}
                                                placeholder="Wallet address"
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">Description</p>

                                    <div className="service__item--content">
                                        {isEditMode ? (
                                            <textarea
                                                className="input service__item--textarea long"
                                                value={userData && userData.description}
                                                disabled={true}
                                            ></textarea>
                                        ) : (
                                            <textarea
                                                className="input service__item--textarea long"
                                                placeholder="Enter a description of the user (if necessary)"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            ></textarea>
                                        )}

                                        {/* <p className="service__item--input--char">500 characters</p> */}
                                    </div>
                                </div>
                                {reqStatus.text.length !== 0 && (
                                    <p
                                        style={{
                                            color: reqStatus.status === 200 ? 'green' : '#CC5554',
                                        }}
                                    >
                                        {reqStatus.text}
                                    </p>
                                )}
                                <button
                                    className="button service__confirm"
                                    onClick={() => handleCreateAdmin()}
                                >
                                    {isEditMode ? 'Изменить' : 'Создать'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateAdmin;
