import React from 'react';
import cn from 'classnames';

import styles from '../index.module.css';
import { Link } from 'react-router-dom';
import ImgBlockItem from '../../../components/ImgBlock/ImgBlockItem';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { S3Context } from '../../../context/S3Context';

const CertificatesItem = ({ certificate }) => {
    const {
        id,
        name,
        price,
        file_1,
        investor_royalty: investorRoyalty,
    } = certificate;
    const {
        actions: { getImageBacketPath },
    } = React.useContext(S3Context);

    return (
        <div className={ styles.certificateItemWrapper }>
            <div className={ styles.brandItem }>
                <img src="/assets/img/element-fire.png" alt="decor" className={ styles.brandItemDecor } />
                <Link to={ `/token/${id}` } className={ styles.certificateItemImgInner }>
                    <img src={ getImageBacketPath(file_1) } alt={ name } />
                </Link>

                <div className={ styles.certificateItemInfo }>
                    <p className={ styles.brandItemInfoName }>{ name }</p>

                    <div className={ styles.certificateItemInfoTags }>
                        <p className={ styles.brandItemInfoTag }>{ new Intl.NumberFormat('ru-RU').format(price) } RUB</p>
                    </div>

                    <p className={ styles.certificateItemText }>Доходность: { investorRoyalty }%/день</p>

                    <button className={ styles.certificateItemButton }>
                        Купить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CertificatesItem;
