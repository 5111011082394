import React, {
    useMemo,
    useRef,
    useState,
    useLayoutEffect,
    useCallback,
    useEffect,
    useContext,
} from 'react';
import { cnb } from 'cnbuilder';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DeleteEntityDialog from '../../../components/DeleteEntityDialog/DeleteEntityDialog';
import { pagesSelectors } from '../../../redux/slices/pages';
import { onOpen, onClose } from '../../../redux/dialogs/deleteEntityDialog';
import WithImageCell from '../../../common/Table/cells/WithImageCell';
import useDebounce from '../../../hooks/useDebounce';
import { useGetAccountsQuery, useHideAccountMutation } from '../../../redux/api/dataService';
import ActionsComponent from '../ActionsComponent';
import { CommonCell, ActionsCell } from '../../../common/Table/cells';
import { Table } from '../../../common/Table';
import { normilizeError } from '../../../utils/http/normilizeError';
import CenteredContainer from '../../../common/CenteredContainer';
import Loader from '../../../common/Loader';
import {
    EDIT_ACCOUNT_PAGE,
    STATISTICS_ACCOUNT_COLLECTIONS_LIST,
} from '../../../const/http/CLIENT_URLS';
import { NotificationContext } from '../../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';

import index from './index.module.css';
import css from '../Statistics.module.css';
import FilterTable from '../../../components/FilterTable';
import AccountItem from './AccountItem';
import TableRow from '../../../components/TableRow';
import TableCell from '../../../components/TableRow/TableCell';
import { BREAKPOINTS, BREAKPOINTS_ACTIVE } from '../../../const/breakpoints/BREAKPOINTS';

const HEDER_CELLS = {
    BRAND: 'Brand',
    COLLECTIONS: 'Collections',
    PROFITS: 'Profit',
    DESCRIPTION: 'Description',
    URL: 'Url',
    CATEGORY: 'Category',
    ACTION: 'Action',
};

const BODY_CELLS = {
    NAME: 'name',
    COLLECTIONS_COUNT: 'collections_count',
    PROFIT: 'profit',
    DESCRITPION: 'description',
    URL: 'url',
    CATEGORY: 'category',
    ACTION: 'action',
};

const HEADER_CELLS_ARR = [
    { label: HEDER_CELLS.BRAND, xs: 3 },
    { label: HEDER_CELLS.COLLECTIONS, xs: 2 },
    { label: HEDER_CELLS.PROFITS, xs: 2 },
    { label: HEDER_CELLS.DESCRIPTION, xs: 2 },
    { label: HEDER_CELLS.URL, xs: 2 },
    { label: HEDER_CELLS.CATEGORY, xs: 2 },
    { label: '', xs: 2, isAction: true },
];

const TABLE_BOTTOM_MARGIN = 20;

const AccountsList = () => {
    const { isOpen, id: deletedAccountId } = useSelector((state) => state.deleteEntityDialog);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const beforeTableDiv = useRef(null);

    const pages = useSelector(pagesSelectors.selectAll);

    const [accounts, setAccounts] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [tableHeight, setTableHeight] = useState(0);

    const { data, error, isLoading, isFetching, refetch } = useGetAccountsQuery(
        {
            page,
            pageSize: rowsPerPage,
        }
    );

    const [
        hideAccount,
        {
            isSuccess,
            isLoading: isDeletationProccessing,
            error: hideAccountError,
            reset: resetDeletationState,
        },
    ] = useHideAccountMutation();

    const onEditHandler = useCallback((id) => {
        navigate(EDIT_ACCOUNT_PAGE({ id }));
    }, []);

    const onDeleteHandler = useCallback((id) => {
        hideAccount({ id });
    }, []);

    const onDeleteAccount = useCallback((id) => {
        dispatch(
            onOpen({
                id,
                onDelete: onDeleteHandler,
                title: 'Are you sure you want to delete acсount?',
            }),
        );
    }, []);

    const closeDialogHandler = useCallback(() => {
        dispatch(onClose());
    }, []);

    const headerCellsArray = useMemo(() => {
        return HEADER_CELLS_ARR.map((cell) => ({
            label: cell.label,
            labelComponent: (
                <div className={css.headingCellBox}>
                    <span className={css.headerText}>{cell.label}</span>
                </div>
            ),
            xs: cell.xs,
        }));
    }, []);

    const bodyRowsArray = useMemo(() => {
        if (!accounts || !accounts.length) {
            return [];
        }

        const columns = Object.values(BODY_CELLS);

        const bodyRows = accounts.map((a) => {
            const cellsArray = [];

            columns.forEach((name) => {
                switch (name) {
                    case BODY_CELLS.NAME:
                        cellsArray.push({
                            label: name,
                            xs: 3,
                            component: (
                                <WithImageCell
                                    classes={{
                                        cellRoot: cnb(css[`${name}Cell`], css.cellRoot),
                                        imageRoot: css.imageRoot,
                                    }}
                                    imageUrl={a.logo}
                                    value={a.name}
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.ACTION:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            disableRowClickEvent: true,
                            horizontalPosition: 'flex-end',
                            component: (
                                <ActionsCell
                                    classes={{ cellRoot: css.buttonsCell }}
                                    actionsComponent={
                                        <ActionsComponent
                                            id={a.id}
                                            onEdit={onEditHandler}
                                            onDelete={onDeleteAccount}
                                        />
                                    }
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.PROFIT:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={Number(a.profit)}
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.CATEGORY:
                        const currentPage = pages.find((p) => p.id === a.page);

                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={String(currentPage ? currentPage.name : '-')}
                                />
                            ),
                        });
                        return;
                    default:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={String(a[name])}
                                />
                            ),
                        });
                }
            });

            return {
                id: a.id,
                linkUrl: STATISTICS_ACCOUNT_COLLECTIONS_LIST({ accountId: a.id }),
                items: cellsArray,
            };
        });

        return bodyRows;
    }, [accounts, pages]);

    const handleChangePage = useCallback((_, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(1);
    };

    useDebounce(
        () => {
            refetch({
                page,
                pageSize: rowsPerPage,
            });
        },
        [page, rowsPerPage],
        500,
    );

    useLayoutEffect(() => {
        if (beforeTableDiv.current) {
            const offset = beforeTableDiv.current.getBoundingClientRect();
            setTableHeight(
                Number(window.innerHeight || 0) -
                    (Number(offset.bottom || 0) + TABLE_BOTTOM_MARGIN),
            );
        }
    }, [accounts]);

    useEffect(() => {
        if (hideAccountError) {
            closeDialogHandler();
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(hideAccountError),
            });
        }
    }, [hideAccountError]);

    useEffect(() => {
        if (isSuccess && deletedAccountId) {
            setAccounts((p) => p.filter((a) => a.id !== deletedAccountId));

            closeDialogHandler();
            resetDeletationState();
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Account deleted successfuly',
            });
        }
    }, [isSuccess, deletedAccountId]);

    useEffect(() => {
        if (data && data.results) {
            setAccounts(data.results);
            setCount(data.count);
        }
    }, [data]);

    if (isLoading) {
        return (
            <CenteredContainer>
                <Loader />
            </CenteredContainer>
        );
    }

    if (error || isLoading) {
        return (
            <div>
                <span style={{ color: 'white' }}>{normilizeError(error)}</span>
            </div>
        );
    }

    return (
        <>
            <div className="statistics__inner">
                <h2 className="statistics__title">Accounts</h2>
                <div ref={beforeTableDiv} className={css.beforeTableElement} />
                <Table
                    tableHeight={tableHeight}
                    tableInfo={bodyRowsArray}
                    headerInfo={headerCellsArray}
                    count={count}
                    page={page - 1}
                    isLoading={isFetching}
                    isNoResultFound={!accounts.length}
                    notFoundPlug={<div className={css.notFoundContainer}>No results found</div>}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            {isOpen && (
                <DeleteEntityDialog
                    open={isOpen}
                    isDeletationProccessing={isDeletationProccessing}
                    onClose={closeDialogHandler}
                />
            )}
        </>
    );
};

export default React.memo(AccountsList);
