import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import CollectionItem from '../BrandsNew/components/CollectionItem';
import Light from '../../icons/Light';
import Card from '../../icons/Card';
import Order from '../../icons/Order';
import Cash from '../../icons/Cash';
import Web from '../../icons/Web';
import Download from '../../icons/Download';
import Doc from '../../icons/Doc';
import StepItem from './components/StepItem';
import WhatsApp from '../../icons/WhatsApp';
import Telegram2 from '../../icons/Telegram2';
import ArrowLink from '../../icons/ArrowLink';
import FaqItem from './components/FaqItem';
import ArrowLeftShort from '../../icons/ArrowLeftShort';
import ArrowRightShort from '../../icons/ArrowRightShort';
import Clothes from '../../icons/Clothes';
import Alcohol from '../../icons/Alcohol';
import Car from '../../icons/Car';
import Watch from '../../icons/Watch';
import Check2 from '../../icons/Check2';
import MoneyBust from '../../icons/MoneyBust';
import Raise from '../../icons/Raise';
import Eye from '../../icons/Eye';
import Crown from '../../icons/Crown';
import Check3 from '../../icons/Check3';
import Vk from '../../icons/Vk';
import Instagram from '../../icons/Instagram';
import Youtube from '../../icons/Youtube';
import Twitter from '../../icons/Twitter';
import Share from '../../icons/Share';
import Question2 from '../../icons/Question2';
import Option from '../../icons/Option';
import Rocket from '../../icons/Rocket';
import Diamond from '../../icons/Diamond';
import Bag2 from '../../icons/Bag2';
import ModalNew from '../../common/ModalNew';
import RangeItem from './components/RangeItem';
import Comment from '../../icons/Comment';
import CollectionItemEmpty from '../BrandsNew/components/CollectionItemEmpty';
import { useGetAccountsQuery, useGetCollectionsQuery } from '../../redux/api/dataService';
import { Review } from './components/review/Review';
import { sendForm, sendTariffCard } from './lib/requests';
import { CollabForm } from './components/CollabForm';
import { CertificateApplicationForm } from './components/CertificateApplicationForm/CertificateApplicationForm';
import {
    useGetAccountsListFilteredQuery,
    useGetFilteredCollectionsQuery,
} from '../../redux/api/handleService';
import { S3Context } from '../../context/S3Context';
import { useParams } from 'react-router-dom';
import TeamItem from './components/TeamItem';
const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.05,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 720,
            settings: {
                slidesToShow: 1.05,
            },
        },
    ],
};

const answers = [
    'Токенизируйте - создайте для клиентов коллекцию цифровых сертификатов',
    'Лидируйте - возглавьте новую эру токенизированной брендовой одежды',
    'Наполняйте - подключите к своей продукции товары и услуги партнёров',
];

const questions = [
    'Как бренду одежды привлечь внимание к своей продукции?',
    'Как бренду одежды победить в конкуренции с другими брендами?',
    'Как бренду одежды поднять стоимость своего товара?',
];

const startTariff = [
    {
        count: 100,
        price: 99,
    },
];
const businessTariff = [
    {
        count: 1000,
        price: 89,
    },
    {
        count: 3000,
        price: 79,
    },
];

const premiumTariff = [
    {
        count: 6000,
        price: 69,
    },
    {
        count: 10000,
        price: 59,
    },
    {
        count: 20000,
        price: 49,
    },
    {
        count: 30000,
        price: 39,
    },
];

const MainNew = () => {
    const [faqNumber, setFaqNumber] = React.useState(1);
    const [faqNumber2, setFaqNumber2] = React.useState(1);
    const [faqNumber3, setFaqNumber3] = React.useState(1);
    const [orderThx, setOrderThx] = React.useState(false);
    const [testModal, setTestModal] = React.useState(false);
    const [teamModal, setTeamModal] = React.useState(false);
    const [valueOrderCheckbox, setValueOrderCheckbox] = React.useState({
        offer: false,
        conditions: false,
    });

    const navigate = useNavigate();
    const pageQuery = window.location.search;
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [activeIndexQuestion, setActiveIndexQuestion] = React.useState(0);
    const [selectedTariff, setSelectedTariff] = React.useState({});
    const [userEmail, setUserEmail] = React.useState('');
    const [paymentMerhod, setPaymentMethod] = React.useState('card');

    const loadDocument = () => {
        const fileUrl = '/assets/1.pdf';
        const fileName = 'sample_agreement.pdf';

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();
    }

    const handlePayment = async () => {
        switch (paymentMerhod) {
            case 'card':
                const resultCard = await sendTariffCard(userEmail, selectedTariff.count);
                window.open(resultCard.payment_url, '_blank');
                setTestModal(false);
                // setOrderThx(true);
                break;
            case 'cash':
                break;
            case 'web':
                break;
            case 'order':
                break;
            default:
                const result = await sendTariffCard(userEmail, selectedTariff.count);
                window.open(result.payment_url, '_blank');
                setTestModal(false);
                
                break;
        }
        setUserEmail('');
    };

    const [stateStart, setStateStart] = React.useState({
        totalPrice: 9900,
        count: 100,
    });
    const [stateBusiness, setStateBusiness] = React.useState({
        totalPrice: 89000,
        count: 1000,
    });
    const [statePremium, setStatePremium] = React.useState({
        totalPrice: 414900,
        count: 6000,
    });

    const { data: accaunts, isLoading } = useGetAccountsListFilteredQuery({
        page: 1,
        pageSize: 8,
    });

    const { data: collections, isLoading: isLoadingCollection } = useGetFilteredCollectionsQuery({
        page: 1,
        pageSize: 8,
    });

    let sliderRef = React.useRef(null);

    const location = useLocation();

    const next = () => {
        sliderRef.slickNext();
    };

    const previous = () => {
        sliderRef.slickPrev();
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % answers.length);
            setActiveIndexQuestion((prevIndex) => (prevIndex + 1) % questions.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (window.location.search === '?payment=success') {
            setOrderThx(true);
        }
    },[window.location])

    React.useEffect(() => {
        // Извлекаем хэш из URL и прокручиваем до выбранного блока
        const hash = location.hash;
        scrollToHash(hash);
    }, [location.hash]);

    // console.log(collections)
    const {
        actions: { getImageBacketPath },
    } = React.useContext(S3Context);
    const scrollToHash = (hash) => {
        if (hash) {
            /* 
              Проверяем наличие хеша в URL.
              Если элемент с указанным идентификатором существует:
                - Удаляем хеш из адресной строки.
                - Прокручиваем страницу к соответствующему блоку.
                - Очищаем хеш в location для возможности повторного использования того же пункта меню.
            */

            const element = document.querySelector(hash);
            if (element) {
                window.history.replaceState(null, '', window.location.pathname);
                element.scrollIntoView({ behavior: 'smooth' });
                location.hash = '';
            }
        }
    };
    const emailRegex = /\S+@\S+\.\S+/;

    return (
        <div className={styles.marginWrapper}>
            <section className={styles.mainnew}>
                <img src="/assets/img/main-bg2.png" alt="main bg" className={styles.mainnewBg} />
                <img
                    src="/assets/img/main-woman.svg"
                    alt="main woman"
                    className={styles.mainnewWoman}
                />
                <img
                    src="/assets/img/main-bg-mobile.svg"
                    alt="main woman"
                    className={styles.mainnewWomanMobile}
                />
                <img
                    src="/assets/img/main-shadow.svg"
                    alt="main shadow"
                    className={styles.mainnewShadow}
                />

                <div className={base.container}>
                    <div className={styles.mainnewInner}>
                        <div className={styles.mainnewWrapper}>
                            <p className={styles.mainTitle}>
                                Платформа токенизации брендовых вещей
                            </p>

                            <button
                                className={cn(base.orangeButton, styles.mainButton)}
                                onClick={() => {
                                    scrollToHash('#start_cooperation');
                                }}
                            >
                                Начать токенизацию продукции
                            </button>
                        </div>

                        <div className={styles.mainnewContent}>
                            <div className={styles.mainnewLeft}>
                                <p className={styles.mainnewSubtext}>Найдите ответы на вопросы:</p>

                                <div className={styles.mainnewComment}>
                                    <Comment />

                                    <div
                                        className={styles.mainnewCarouselAnswers}
                                        // style={{
                                        //     transform: `translateY(calc(-1 * ((${activeIndexQuestion} + 1) * (100% / ${questions.length}) - (100% / ${questions.length}))))`,
                                        // }}
                                    >
                                        {questions.map((data, index) => (
                                            <p
                                                className={cn(styles.mainnewCarouselAnswer, {
                                                    [styles.active]: index === activeIndexQuestion,
                                                    // [styles.disabled]: index < activeIndexQuestion,
                                                })}
                                            >
                                                {data}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.mainnewRight}>
                                <p className={styles.mainnewSubtext}>CheckBrand предлагает решение:</p>

                                <div className={styles.mainnewAnswer}>
                                    <p
                                        className={cn(styles.mainnewAnswerText, {
                                            [styles.active]: activeIndex === 0,
                                        })}
                                    >
                                        Токенизируйте - создайте для клиентов коллекцию цифровых сертификатов
                                    </p>

                                    <p
                                        className={cn(styles.mainnewAnswerText, {
                                            [styles.active]: activeIndex === 1,
                                        })}
                                    >
                                        Лидируйте - возглавьте новую эру токенизированной брендовой одежды
                                    </p>

                                    <p
                                        className={cn(styles.mainnewAnswerText, {
                                            [styles.active]: activeIndex === 2,
                                        })}
                                    >
                                        Наполняйте - подключите к своей продукции товары и услуги партнёров
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.howit}>
                <img src="/assets/img/blur-right.svg" alt="blur" className={styles.howitBlur} />

                <div className={base.container}>
                    <div id="how_it_work" className={styles.howitInner}>
                        <h2 className={cn(base.title2, styles.howitTitle)}>Как это работает</h2>

                        <div className={styles.howitContent}>
                            <div className={styles.howitItem}>
                                <div className={styles.howitItemImgInner}>
                                    <img
                                        src="/assets/img/element-fire.png"
                                        alt="light"
                                        className={styles.howitItemLight}
                                    />

                                    <img src="/assets/img/how1.svg" alt="how img" />
                                </div>

                                <div className={styles.howitItemWrap}>
                                    <div className={styles.howitTextInner}>
                                        <p className={styles.howitText}>
                                        Мы создаём бренд-токены - цифровые сертификаты, которые можно привязывать к брендовой одежде с помощью QR-кодов
                                        </p>

                                        <div className={styles.howitLightText}>
                                            <Light />
                                            Одежда с бренд-токенами привлекает больше внимания покупателей и повышает лояльность к бренду
                                        </div>
                                    </div>

                                    <div className={styles.howitSubtextInner}>
                                        <p>
                                            QR-код с бренд-токеном может быть частью дизайна одежды или размещен на незаметной детали (например, на фирменном шильде).
                                        </p>

                                        <p>
                                            Это делает одежду более интересной и высокотехнологичной в глазах потребителей.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.howitItem}>
                                <div className={styles.howitItemImgInner}>
                                    <img
                                        src="/assets/img/element-fire.png"
                                        alt="light"
                                        className={styles.howitItemLight}
                                    />

                                    <img src="/assets/img/how2.svg" alt="how img" />
                                </div>

                                <div className={styles.howitItemWrap}>
                                    <div className={styles.howitTextInner}>
                                        <p className={styles.howitText}>
                                            Бренд-токен привязывает одежду к конкретному владельцу, что исключает возможность подделки
                                        </p>

                                        <div className={styles.howitLightText}>
                                            <Light />
                                            Защита бренда от контрафакта позволяет укрепить его престиж и избежать финансовых потерь
                                        </div>
                                    </div>

                                    <div className={styles.howitSubtextInner}>
                                        <p>
                                            Каждая вещь Вашего бренда будет иметь свой уникальный цифровой сертификат, привязанный к владельцу.
                                        </p>

                                        <p>
                                            Это позволит гарантировать подлинность товара и защитить Ваш бренд от подделок.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.howitItem}>
                                <div className={styles.howitItemImgInner}>
                                    <img
                                        src="/assets/img/element-fire.png"
                                        alt="light"
                                        className={styles.howitItemLight}
                                    />

                                    <img src="/assets/img/how3.svg" alt="how img" />
                                </div>

                                <div className={styles.howitItemWrap}>
                                    <div className={styles.howitTextInner}>
                                        <p className={styles.howitText}>
                                            Доступ к дополнительным сервисам и привилегиям для владельцев брендовой одежды
                                        </p>

                                        <div className={styles.howitLightText}>
                                            <Light />
                                            Стоимость брендовой одежды с бренд-токенами может быть на 25-500% выше обычной
                                        </div>
                                    </div>

                                    <div className={styles.howitSubtextInner}>
                                        <p>
                                            Цифровой сертификат служит основанием для получения специальных предложений от партнёров бренда.
                                        </p>

                                        <p>
                                            Вы можете гибко управлять этими предложениями, добавляя или меняя партнёров.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.steps}>
                <img src="/assets/img/blur-left.svg" alt="blur" className={styles.stepsBlur} />

                <div className={base.container}>
                    <div id="stages_and_deadlines" className={styles.stepsInner}>
                        <h2 className={cn(base.title2, styles.stepsTitle)}>
                            Этапы и сроки
                            <br />
                            сотрудничества
                        </h2>

                        <div className={styles.stepsContent}>
                            <StepItem
                                number={1}
                                name="Подписание договора и произведение оплаты"
                                time="от 1-го до 3-х дней"
                                image="/assets/img/sber.svg"
                                openDefault
                            >
                                <div className={styles.stepItemTextInner}>
                                    <div className={styles.stepItemDocument} onClick={loadDocument}>
                                        <div className={styles.stepItemDocumentInner}>
                                            <Doc />

                                            <div className={styles.stepItemDocumentTextInner}>
                                                <p className={styles.stepItemDocumentName}>
                                                    Образец договора
                                                </p>

                                                <p className={styles.stepItemDocumentWeight}>
                                                    115 kb
                                                </p>
                                            </div>
                                        </div>

                                        <Download className={styles.stepItemDocumentDownload} />
                                    </div>

                                    <div className={styles.stepItemVariantsInner}>
                                        <p className={styles.stepItemVariantsTitle}>
                                            Варианты приема оплаты:
                                        </p>

                                        <div className={styles.stepItemVariants}>
                                            <div className={styles.stepItemVariantItem}>
                                                <div className={styles.stepItemVariantItemIcon}>
                                                    <Card />
                                                </div>

                                                <p className={styles.stepItemVariantItemText}>
                                                    карта
                                                </p>
                                            </div>

                                            <div className={styles.stepItemVariantItem}>
                                                <div className={styles.stepItemVariantItemIcon}>
                                                    <Order />
                                                </div>

                                                <p className={styles.stepItemVariantItemText}>
                                                    квитанция
                                                </p>
                                            </div>

                                            <div className={styles.stepItemVariantItem}>
                                                <div className={styles.stepItemVariantItemIcon}>
                                                    <Cash />
                                                </div>

                                                <p className={styles.stepItemVariantItemText}>
                                                    наличные
                                                </p>
                                            </div>

                                            <div className={styles.stepItemVariantItem}>
                                                <div className={cn(styles.stepItemVariantItemIcon, styles.small)}>
                                                    <Web />
                                                </div>

                                                <p className={styles.stepItemVariantItemText}>
                                                    платежная система
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <p className={styles.stepItemSubtitle}>
                                        После подписания договора и произведения оплаты переходим к
                                        выпуску сертификатов
                                    </p>
                                </div>
                            </StepItem>

                            <StepItem
                                number={2}
                                name="Разработка дизайна цифровых сертификатов"
                                time="от 3-х до 10-и дней"
                                image="/assets/img/step2.svg"
                            >
                                <div className={styles.stepItemTextInner}>
                                    <p className={styles.stepItemSubtitle}>
                                        Специалисты CheckBrand подготовят дизайн цифровых сертификатов
                                    </p>

                                    <ul className={styles.stepItemUl}>
                                        <li>Разработаем дизайн и создадим изображения для Ваших цифровых сертификатов.</li>
                                        <li>Осуществим разработку текстового контента и описаний для Вашей коллекции цифровых сертификатов.</li>
                                    </ul>

                                    <Link
                                        className={cn(base.orangeButton, styles.stepItemButton)}
                                        to="/brandsnew/certificates"
                                    >
                                        Смотреть циф. сертификаты
                                    </Link>
                                </div>
                            </StepItem>

                            <StepItem
                                number={3}
                                name="Выпуск цифровых сертификатов"
                                time="от 1-го до 2-х дней"
                                image="/assets/img/step3.png"
                            >
                                <div className={styles.stepItemTextInner}>
                                    <p className={styles.stepItemSubtitle}>
                                        После утверждения дизайна мы выпустим цифровые сертификаты
                                    </p>

                                    <ul className={styles.stepItemUl}>
                                        <li>Разместим на платформе коллекцию бренда.</li>
                                        <li>Добавим в коллекцию согласованное количество цифровых сертификатов.</li>
                                        <li>Наполним коллекцию подробными описаниями товаров и привлечём партнеров для популяризации.</li>
                                    </ul>

                                    <Link
                                        className={cn(base.orangeButton, styles.stepItemButton)}
                                        to="brandsnew/collections"
                                    >
                                        Смотреть коллекции брендов
                                    </Link>
                                </div>
                            </StepItem>

                            <StepItem
                                number={4}
                                name="Предоставление QR-кодов для размещения на продукции"
                                time="от 1-го до 5-и дней"
                                image="/assets/img/step4.png"
                            >
                                <div className={styles.stepItemTextInner}>
                                    <p className={styles.stepItemSubtitle}>
                                        На этом этапе мы передадим Вам уникальные QR-кода для нанесения на продукцию
                                    </p>

                                    <p className={cn(styles.stepItemSubtext, styles.margin)}>
                                        Эти QR-коды нужны для связи физического товара с его цифровой версией.
                                    </p>

                                    <p className={styles.stepItemSubtext}>
                                        Их можно разместить прямо на Вашей продукции - например, в виде скрытой бирки или как часть общего дизайна.
                                    </p>

                                    <p className={styles.stepItemSubtext}>
                                        Или же передавать покупателям отдельно, в качестве брелоков или карточек бренда.
                                    </p>

                                    <p className={styles.stepItemSubtext}>
                                        Специалисты CheckBrand помогут выбрать лучший способ размещения QR-кодов, чтобы максимально эффективно оцифровать Ваши товары.
                                    </p>
                                </div>
                            </StepItem>

                            <StepItem
                                number={5}
                                name="Передача цифровых сертификатов покупателям продукции"
                                time="До окончания сотрудничества"
                                image="/assets/img/step5.png"
                            >
                                <div className={styles.stepItemTextInner}>
                                    <p className={styles.stepItemSubtitle}>
                                        Команда CheckBrand поможет вашим клиентам получить цифровые сертификаты
                                    </p>

                                    <p className={cn(styles.stepItemSubtext, styles.margin)}>
                                        После того, как Вы разместите QR-коды на своей продукции, покупателям останется только отсканировать их, и CheckBrand автоматически передаст им цифровые сертификаты.
                                    </p>

                                    <p className={styles.stepItemSubtext}>
                                        Круглосуточная служба поддержки CheckBrand всегда готова помочь, если у Ваших клиентов возникнут какие-либо вопросы.
                                    </p>

                                    <p className={styles.stepItemSubtext}>
                                        Так Вы сможете сосредоточиться на удовлетворении растущего спроса на Ваши товары, а техническая часть будет под контролем CheckBrand.
                                    </p>
                                </div>
                            </StepItem>
                        </div>
                    </div>
                </div>
            </section>
            <CertificateApplicationForm setOrderThx={setOrderThx} />
            <section className={styles.tarif}>
                <img src="/assets/img/blur-right.svg" alt="blur" className={styles.tarifBlur} />
                <img src="/assets/img/blur-left.svg" alt="blur" className={styles.tarifBlur2} />

                <div className={base.container}>
                    <div id="rate" className={styles.tarifInner}>
                        <h2 className={cn(base.title2, styles.tarifTitle)}>Выберите тариф</h2>

                        <div className={styles.tarifContent}>
                            <div className={styles.tarifNames}>
                                <p className={styles.tarifName}>
                                    Разработка дизайнов для сертификатов
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Создание уникальных изображений и описаний продуктов бренда
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Оформление дизайна коллекций бренда
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Разработка фирменного дизайна бренда, включая баннеры, логотипы и текстовые описания
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Разработка оформления продукции
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Интеграция QR-кода в дизайн одежды бренда
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Дизайн карточки бренда с QR-кодом
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Создание карточки бренда с QR-кодом и инструкцией по использованию
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Фирменная эмблема с размещением  QR-кода
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Разработка фирменной эмблемы для скрытого размещения на изделиях
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Передача прав на созданные произведения
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Оформление передачи прав на созданные дизайнерские работы
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Стратегия продажи с просчетом бюджета
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Наши маркетологи и аналитики проведут исследование рынка, разработают эффективный маркетинговый план и будут сопровождать его реализацию
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Подключение партнеров к сертификату
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Мы предоставим Вам список потенциальных партнеров, товары и услуги которых могут быть включены в сертификаты, чтобы Вы могли выбрать наиболее подходящие варианты
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <p className={styles.tarifName}>
                                    Сопровождение бизнеса
                                    <div className={styles.tooltipInner}>
                                        <Question2 />

                                        <div className={styles.tooltipContentWrapper}>
                                            <div className={styles.tooltipContent}>
                                                Наша техническая поддержка поможет вашим клиентам с передачей и использованием сертификатов, а также ответит на любые возникающие вопросы
                                            </div>
                                        </div>
                                    </div>
                                </p>

                                <div className={styles.tarifNamesEmpty}></div>
                            </div>

                            <div className={styles.tarifWrapper}>
                                <div className={styles.tarifItemWrapper}>
                                    <div className={styles.tarifItem}>
                                        <div className={styles.tarifItemOuter}>
                                            <div className={styles.tarifItemIconInner}>
                                                <div className={styles.tarifItemIcon}>
                                                    <Rocket />
                                                </div>
                                            </div>

                                            <p className={styles.tarifItemName}>Старт</p>

                                            <p className={styles.tarifItemPrice}>от 9 900 ₽</p>

                                            <div className={styles.tarifItemButtons}>
                                                <button
                                                    className={styles.tarifItemButtonChoose}
                                                    onClick={() => {
                                                        setSelectedTariff({
                                                            name: 'Старт',
                                                            count: stateStart.count,
                                                            price: stateStart.totalPrice,
                                                        });
                                                        // setTestModal(true);
                                                    }}
                                                >
                                                    Выбрать Старт
                                                </button>

                                                <div className={styles.tarifItemButtonInInner}>
                                                    <button className={styles.tarifItemButtonIn}>
                                                        Выпуск {stateStart.count} шт.
                                                        <Option />
                                                    </button>

                                                    <div className={styles.tarifSettingsWrapper}>
                                                        <div className={styles.tarifSettings}>
                                                            <RangeItem
                                                                min={100}
                                                                max={999}
                                                                state={stateStart}
                                                                setState={setStateStart}
                                                                tariffs={startTariff}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.tarifItemPoints}>
                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Разработка дизайнов для сертификатов
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Создание уникальных изображений и описаний продуктов бренда
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={cn(
                                                        styles.tarifItemPoint,
                                                        styles.line,
                                                    )}
                                                >
                                                    <span>3</span> статических варианта
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Оформление дизайна коллекций бренда
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Разработка фирменного дизайна бренда, включая баннеры, логотипы и текстовые описания
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={styles.tarifItemPoint}
                                                >
                                                    <span>1</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Разработка оформления продукции
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Интеграция QR-кода в дизайн одежды бренда
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={styles.tarifItemPoint}
                                                >
                                                    <span>1</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Дизайн карточки бренда с QR-кодом
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Создание карточки бренда с QR-кодом и инструкцией по использованию
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Фирменная эмблема с размещением  QR-кода
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Разработка фирменной эмблемы для скрытого размещения на изделиях
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={cn(
                                                        styles.tarifItemPoint,
                                                        styles.tarifPointNone,
                                                    )}
                                                >
                                                    —
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Передача прав на созданные произведения
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Оформление передачи прав на созданные дизайнерские работы
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={cn(
                                                        styles.tarifItemPoint,
                                                        styles.tarifPointNone,
                                                    )}
                                                >
                                                    —
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Стратегия продажи с просчетом бюджета
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Наши маркетологи и аналитики проведут исследование рынка, разработают эффективный маркетинговый план и будут сопровождать его реализацию
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={cn(
                                                        styles.tarifItemPoint,
                                                        styles.tarifPointNone,
                                                    )}
                                                >
                                                    —
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Подключение партнеров к сертификату
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Мы предоставим Вам список потенциальных партнеров, товары и услуги которых могут быть включены в сертификаты, чтобы Вы могли выбрать наиболее подходящие варианты
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span>1</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Сопровождение бизнеса
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Наша техническая поддержка поможет вашим клиентам с передачей и использованием сертификатов, а также ответит на любые возникающие вопросы
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span>1</span> месяц
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.tarifItemBottom}>
                                        <p className={styles.tarifItemBottomPrice}>
                                            {stateStart.totalPrice === 9900 ? 'от' : ''}
                                            <span>
                                                {' '}
                                                {new Intl.NumberFormat('ru-RU').format(
                                                    stateStart.totalPrice,
                                                )}{' '}
                                            </span>{' '}
                                            ₽
                                        </p>

                                        <button
                                            className={cn(
                                                base.orangeButton,
                                                styles.tarifItemButtonBottom,
                                            )}
                                            onClick={() => {
                                                setSelectedTariff({
                                                    name: 'Старт',
                                                    count: stateStart.count,
                                                    price: stateStart.totalPrice,
                                                });
                                                setTestModal(true);
                                            }}
                                        >
                                            Выбрать Старт
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.tarifItemWrapper}>
                                    <div className={styles.tarifItem}>
                                        <div className={styles.tarifItemOuter}>
                                            <div className={styles.tarifItemIconInner}>
                                                <img
                                                    src="/assets/img/tarif-light.png"
                                                    alt="light"
                                                    className={styles.tarifItemIconLight}
                                                />

                                                <div className={styles.tarifItemIcon}>
                                                    <Bag2 />
                                                </div>
                                            </div>

                                            <p className={styles.tarifItemName}>Бизнес</p>

                                            <p className={styles.tarifItemPrice}>от 89 000 ₽</p>

                                            <div className={styles.tarifItemButtons}>
                                                <button
                                                    className={styles.tarifItemButtonChoose}
                                                    onClick={() => {
                                                        setTestModal(true);
                                                        setSelectedTariff({
                                                            name: 'Бизнес',
                                                            count: stateBusiness.count,
                                                            price: stateBusiness.totalPrice,
                                                        });
                                                    }}
                                                >
                                                    Выбрать Бизнес
                                                </button>

                                                <div className={styles.tarifItemButtonInInner}>
                                                    <button className={styles.tarifItemButtonIn}>
                                                        Выпуск {stateBusiness.count} шт.
                                                        <Option />
                                                    </button>

                                                    <div className={styles.tarifSettingsWrapper}>
                                                        <div className={styles.tarifSettings}>
                                                            <RangeItem
                                                                min={1000}
                                                                max={5999}
                                                                price={89}
                                                                state={stateBusiness}
                                                                setState={setStateBusiness}
                                                                tariffs={businessTariff}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.tarifItemPoints}>
                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Разработка дизайнов для сертификатов
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Создание уникальных изображений и описаний продуктов бренда
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={cn(
                                                        styles.tarifItemPoint,
                                                        styles.line,
                                                    )}
                                                >
                                                    <span>10</span> статических вариантов
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Оформление дизайна коллекций бренда
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Разработка фирменного дизайна бренда, включая баннеры, логотипы и текстовые описания
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={styles.tarifItemPoint}
                                                >
                                                    <span>3</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Разработка оформления продукции
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Интеграция QR-кода в дизайн одежды бренда
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={styles.tarifItemPoint}
                                                >
                                                    <span>2</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Дизайн карточки бренда с QR-кодом
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Создание карточки бренда с QR-кодом и инструкцией по использованию
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Фирменная эмблема с размещением  QR-кода
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Разработка фирменной эмблемы для скрытого размещения на изделиях
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Передача прав на созданные произведения
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Оформление передачи прав на созданные дизайнерские работы
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Стратегия продажи с просчетом бюджета
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Наши маркетологи и аналитики проведут исследование рынка, разработают эффективный маркетинговый план и будут сопровождать его реализацию
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={cn(
                                                        styles.tarifItemPoint,
                                                        styles.tarifPointNone,
                                                    )}
                                                >
                                                    —
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Подключение партнеров к сертификату
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Мы предоставим Вам список потенциальных партнеров, товары и услуги которых могут быть включены в сертификаты, чтобы Вы могли выбрать наиболее подходящие варианты
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span>3</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Сопровождение бизнеса
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Наша техническая поддержка поможет вашим клиентам с передачей и использованием сертификатов, а также ответит на любые возникающие вопросы
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span>6</span> месяцев
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.tarifItemBottom}>
                                        <p className={styles.tarifItemBottomPrice}>
                                            {stateBusiness.totalPrice === 89000 ? 'от' : ''}
                                            <span>
                                                {' '}
                                                {new Intl.NumberFormat('ru-RU').format(
                                                    stateBusiness.totalPrice,
                                                )}{' '}
                                            </span>{' '}
                                            ₽
                                        </p>

                                        <button
                                            className={cn(
                                                base.orangeButton,
                                                styles.tarifItemButtonBottom,
                                            )}
                                            onClick={() => {
                                                setTestModal(true);
                                                setSelectedTariff({
                                                    name: 'Бизнес',
                                                    count: stateBusiness.count,
                                                    price: stateBusiness.totalPrice,
                                                });
                                            }}
                                        >
                                            Выбрать Бизнес
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.tarifItemWrapper}>
                                    <div className={styles.tarifItem}>
                                        <span className={styles.tarifTop}>
                                            <img src="/assets/img/fire.png" alt="fire" />
                                        </span>

                                        <div className={styles.tarifItemOuter}>
                                            <div className={styles.tarifItemIconInner}>
                                                <img
                                                    src="/assets/img/tarif-light.png"
                                                    alt="light"
                                                    className={styles.tarifItemIconLight}
                                                />

                                                <div className={styles.tarifItemIcon}>
                                                    <Diamond />
                                                </div>
                                            </div>

                                            <p className={styles.tarifItemName}>Премиум</p>

                                            <p className={styles.tarifItemPrice}>от 414 900 ₽</p>

                                            <div className={styles.tarifItemButtons}>
                                                <button
                                                    className={styles.tarifItemButtonChoose}
                                                    onClick={() => {
                                                        setTestModal(true);
                                                        setSelectedTariff({
                                                            name: 'Бизнес',
                                                            count: statePremium.count,
                                                            price: statePremium.totalPrice,
                                                        });
                                                    }}
                                                >
                                                    Выбрать Премиум
                                                </button>

                                                <div className={styles.tarifItemButtonInInner}>
                                                    <button className={styles.tarifItemButtonIn}>
                                                        Выпуск {statePremium.count} шт.
                                                        <Option />
                                                    </button>

                                                    <div className={styles.tarifSettingsWrapper}>
                                                        <div className={styles.tarifSettings}>
                                                            <RangeItem
                                                                min={6000}
                                                                max={30000}
                                                                price={89}
                                                                state={statePremium}
                                                                setState={setStatePremium}
                                                                tariffs={premiumTariff}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.tarifItemPoints}>
                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Разработка дизайнов для сертификатов
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Создание уникальных изображений и описаний продуктов бренда
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={cn(
                                                        styles.tarifItemPoint,
                                                        styles.line,
                                                    )}
                                                >
                                                    <span>10</span> анимированных и <span>20</span> статических вариантов
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Оформление дизайна коллекций бренда
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Разработка фирменного дизайна бренда, включая баннеры, логотипы и текстовые описания
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={styles.tarifItemPoint}
                                                >
                                                    <span>6</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Разработка оформления продукции
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Интеграция QR-кода в дизайн одежды бренда
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p
                                                    className={styles.tarifItemPoint}
                                                >
                                                    <span>8</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Дизайн карточки бренда с QR-кодом
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Создание карточки бренда с QR-кодом и инструкцией по использованию
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Фирменная эмблема с размещением  QR-кода
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Разработка фирменной эмблемы для скрытого размещения на изделиях
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Передача прав на созданные произведения
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Оформление передачи прав на созданные дизайнерские работы
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Стратегия продажи с просчетом бюджета
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Наши маркетологи и аналитики проведут исследование рынка, разработают эффективный маркетинговый план и будут сопровождать его реализацию
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span className={styles.tarifItemCheck}>
                                                        <Check2 />
                                                    </span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Подключение партнеров к сертификату
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Мы предоставим Вам список потенциальных партнеров, товары и услуги которых могут быть включены в сертификаты, чтобы Вы могли выбрать наиболее подходящие варианты
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span>10</span>
                                                </p>
                                            </div>

                                            <div className={styles.tarifItemPointWrapper}>
                                                <p className={styles.tarifName}>
                                                    Сопровождение бизнеса
                                                    <div className={styles.tooltipInner}>
                                                        <Question2 />

                                                        <div className={styles.tooltipContentWrapper}>
                                                            <div className={styles.tooltipContent}>
                                                                Наша техническая поддержка поможет вашим клиентам с передачей и использованием сертификатов, а также ответит на любые возникающие вопросы
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>

                                                <p className={styles.tarifItemPoint}>
                                                    <span>18</span> месяцев
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.tarifItemBottom}>
                                        <p className={styles.tarifItemBottomPrice}>
                                            {statePremium.totalPrice === 414900 ? 'от' : ''}
                                            <span>
                                                {' '}
                                                {new Intl.NumberFormat('ru-RU').format(
                                                    statePremium.totalPrice,
                                                )}{' '}
                                            </span>{' '}
                                            ₽
                                        </p>

                                        <button
                                            className={cn(
                                                base.orangeButton,
                                                styles.tarifItemButtonBottom,
                                            )}
                                            onClick={() => {
                                                setTestModal(true);
                                                setSelectedTariff({
                                                    name: 'Премиум',
                                                    count: statePremium.count,
                                                    price: statePremium.totalPrice,
                                                });
                                            }}
                                        >
                                            Выбрать Премиум
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.brands} id="brand">
                <div className={base.container}>
                    <div className={styles.brandsInner}>
                        <h2 className={base.title2}>Бренды компаний</h2>

                        <p className={styles.brandsText}>
                            успешно выпустившие сертификаты на рынок:
                        </p>

                        <div className={styles.brandsContent}>
                            {Array.from({ length: 8 }).map((_, index) =>
                                isLoadingCollection ? (
                                    <CollectionItemEmpty key={index} />
                                ) : collections &&
                                  collections.results &&
                                  collections.results[index] ? (
                                    <CollectionItem
                                        key={index}
                                        collection={collections.results[index]}
                                    />
                                ) : (
                                    <CollectionItemEmpty key={index} />
                                ),
                            )}
                        </div>

                        <div className={styles.brandsAll}>
                            <button
                                className={cn(base.orangeButton, styles.brandsAllButton)}
                                onClick={() => {
                                    navigate(`/brandsnew/collections`);
                                }}
                            >
                                Смотреть все бренды
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.team} id="team">
                <img src="/assets/img/blur-right.svg" alt="blur" className={styles.teamBlur} />

                <div className={base.container}>
                    <div className={styles.teamInner}>
                        <div className={styles.teamWrapper}>
                            <h2 className={cn(base.title2, styles.teamTitle)}>Команда проекта</h2>

                            <p className={styles.teamSubtitle}>
                                Наша миссия в том что бы ваш бренд становился большим и сильным,
                                <br />а пользователи счастливыми и довольными
                            </p>

                            <div className={styles.teamArrows}>
                                <button className={styles.teamArrow} onClick={previous}>
                                    <ArrowLeftShort />
                                </button>

                                <button className={styles.teamArrow} onClick={next}>
                                    <ArrowRightShort />
                                </button>
                            </div>

                            <p className={styles.teamText}>
                                Представляем вам самых выдающихся членов нашего дружного сообщества,
                                которые меняют мир к лучшему
                            </p>
                        </div>

                        <div className={styles.teamSlider}>
                            <Slider
                                ref={(slider) => {
                                    sliderRef = slider;
                                }}
                                {...settings}
                            >
                                <TeamItem
                                    name="Михляев Игорь"
                                    role="CEO (Генеральный директор)"
                                    image="/assets/img/team1.png"
                                >
                                    <p>
                                        Игорь Михляев - основатель и CEO платформы CheckBrand. С самого детства Игорь мечтал изменить мир к лучшему. Повзрослев и став предпринимателем, он понял, что одна из главных проблем, требующих решения - это масштабная подделка брендовых товаров.
                                    </p>

                                    <p>
                                        Движимый этой целью, Игорь создал CheckBrand - инновационную платформу, которая позволяет любому бренду надежно защитить свою продукцию от контрафакта. Используя технологию токенизации, CheckBrand дает возможность производителям маркировать товары уникальными цифровыми сертификатами, которые невозможно подделать. Это гарантирует подлинность изделий для конечных потребителей.
                                    </p>

                                    <p>
                                        Но Игорь пошел еще дальше. Он разработал целую экосистему вокруг токенизированных брендов, включающую партнерские программы, клубы коллекционеров и системы поощрения. Это позволяет брендам не только защитить свою продукцию, но и выстроить более тесные, вовлекающие отношения с лояльными клиентами.
                                    </p>

                                    <p>
                                        Благодаря своей дальновидности, предпринимательскому таланту и безграничной преданности идее, Игорь Михляев заслужил репутацию лидера, способного воплотить в жизнь грандиозные планы по трансформации рынка брендовых товаров. Работая с ним, бренды могут быть уверены в надежной защите своей продукции и выходе на качественно новый уровень взаимодействия с потребителями.
                                    </p>
                                </TeamItem>
                                
                                <TeamItem
                                    name="Михляева Мария"
                                    role="CMO (Директор по маркетингу)"
                                    image="/assets/img/team2.png"
                                >
                                    <p>
                                        Мария Михляева - директор по маркетингу платформы CheckBrand. Будучи супругой основателя Игоря Михляева, Мария разделяет его страсть к инновациям и стремление сделать мир лучше.
                                    </p>

                                    <p>
                                        Обладая глубокими знаниями в области брендинга и маркетинговых коммуникаций, Мария играет ключевую роль в формировании уникального позиционирования CheckBrand. Она создает яркий, запоминающийся образ платформы, который вдохновляет бренды присоединиться к революции в защите подлинности товаров.
                                    </p>

                                    <p>
                                        Под руководством Марии команда CheckBrand разработала комплексную маркетинговую стратегию, позволяющую эффективно взаимодействовать с целевой аудиторией - производителями брендовой продукции. Ее креативный подход и умение находить нестандартные решения помогают платформе выделяться среди конкурентов и привлекать все больше партнеров.
                                    </p>

                                    <p>
                                        Мария Михляева - не просто опытный маркетолог, но и единомышленник Игоря, разделяющий его видение будущего рынка товаров класса люкс. Вместе они формируют сплоченную управленческую команду, способную вывести CheckBrand на лидирующие позиции в индустрии.
                                    </p>
                                </TeamItem>

                                <TeamItem
                                    name="Калекин Андрей"
                                    role="CTO (Технический директор)"
                                    image="/assets/img/team3.png"
                                >
                                    <p>
                                        Андрей Калекин - CTO и ведущий разработчик платформы CheckBrand. Обладая глубокими познаниями в области блокчейна, криптографии и распределенных реестров, Андрей стоял у истоков создания инновационной технологии, лежащей в основе CheckBrand.
                                    </p>

                                    <p>
                                        Именно благодаря его усилиям CheckBrand может предложить брендам надежную систему защиты от подделок, основанную на невзаимозаменяемых токенах. Андрей разработал уникальный протокол, позволяющий генерировать цифровые сертификаты, которые невозможно подделать или скопировать. Это гарантирует аутентичность каждого товара для конечных потребителей.
                                    </p>

                                    <p>
                                        Но технологические решения Андрея вышли далеко за рамки простой защиты от контрафакта. Он спроектировал целостную экосистему токенизированных брендов, включающую партнерские программы, системы поощрения и клубы коллекционеров. Это позволяет брендам не только защитить свою продукцию, но и выстроить более глубокие, вовлекающие отношения с лояльными клиентами.
                                    </p>

                                    <p>
                                        Благодаря своим выдающимся техническим навыкам, инженерному таланту и страсти к инновациям, Андрей Калекин заслужил репутацию ведущего эксперта в области блокчейн-решений для брендовой индустрии. Работая с ним, бренды могут быть уверены в надежности и масштабируемости технологической платформы CheckBrand.
                                    </p>
                                </TeamItem>

                                <TeamItem
                                    name="Амирханов Эльман"
                                    role="Front end developer (Разработчик)"
                                    image="/assets/img/team4.png"
                                >
                                    <p>
                                        Эльман Амирханов - талантливый Front-end разработчик и ключевой член команды CheckBrand. Именно его усилиями была создана яркая, интуитивно понятная и функциональная веб-платформа, которая сегодня является лицом проекта.
                                    </p>

                                    <p>
                                        Обладая глубокими знаниями в области современных веб-технологий, Эльман спроектировал и реализовал все пользовательские интерфейсы CheckBrand. Он продумал каждую деталь, чтобы обеспечить максимальное удобство и вовлеченность клиентов - брендов и их покупателей.
                                    </p>

                                    <p>
                                        Благодаря его таланту, платформа CheckBrand выглядит не просто как технический инструмент, а как стильное, эстетичное решение, способное заинтересовать и увлечь пользователей. Эльман создал уникальный дизайн, который гармонично сочетает в себе элементы роскоши, эксклюзивности и инновационности - ключевые ценности CheckBrand.
                                    </p>

                                    <p>
                                        Эльман - настоящий профессионал своего дела, способный воплотить самые амбициозные идеи в жизнь. Его работа над CheckBrand стала ярким примером того, как талант и креативность могут трансформировать простую технологическую платформу в захватывающий, вдохновляющий продукт.
                                    </p>
                                </TeamItem>

                                <TeamItem
                                    name="Потапов Виктор"
                                    role="Front end developer (Разработчик)"
                                    image="/assets/img/team5.png"
                                >
                                    <p>
                                        Виктор Потапов - ключевой специалист в команде CheckBrand, отвечающий за разработку и интеграцию фронтенд части платформы. Обладая глубокими знаниями в области современных веб-технологий, Виктор сыграл ключевую роль в создании интуитивно понятного и визуально привлекательного пользовательского интерфейса CheckBrand.
                                    </p>

                                    <p>
                                        Под его руководством была разработана удобная система взаимодействия брендов и их клиентов с платформой токенизации. Виктор обеспечил бесшовную интеграцию фронтенда и бэкенда, что позволило реализовать все инновационные функции CheckBrand - от генерации уникальных цифровых сертификатов до организации клубов коллекционеров брендовых товаров.
                                    </p>

                                    <p>
                                        Благодаря профессионализму и творческому подходу Виктора, пользователи CheckBrand получают исключительно комфортный и вовлекающий опыт взаимодействия с платформой. Это способствует росту лояльности брендов и их клиентов, открывая новые возможности для развития экосистемы токенизированных товаров.
                                    </p>

                                    <p>
                                        Виктор Потапов - ключевой специалист, чей талант и самоотдача помогают воплощать в жизнь грандиозную миссию CheckBrand по защите брендов и их клиентов от контрафакта. Его работа вносит неоценимый вклад в успех всего проекта.
                                    </p>
                                </TeamItem>

                                <TeamItem
                                    name="Сагунов Матвей"
                                    role="Graphic designer (Главный дизайнер)"
                                    image="/assets/img/team6.png"
                                >
                                    <p>
                                        Безупречный графический дизайн - визитная карточка нашей платформы токенизации брендовых вещей CheckBrand. Матвей Сагунов, талантливый дизайнер команды, виртуозно воплощает в жизнь самые смелые творческие идеи.
                                    </p>

                                    <p>
                                        Его работы отличаются безупречным вкусом, оригинальностью и высочайшим уровнем исполнения. Будь то стильный логотип, эффектный баннер или уникальные иллюстрации - Матвей всегда находит идеальное визуальное решение, чтобы в полной мере раскрыть потенциал бренда.
                                    </p>

                                    <p>
                                        Сочетая глубокие профессиональные знания с безграничной фантазией, Матвей создает яркие, запоминающиеся образы, способные вдохновлять и завораживать. Его творческий подход и безупречное чувство стиля неоценимы для продвижения цифровых коллекций брендов на платформе CheckBrand.
                                    </p>

                                    <p>
                                        Благодаря таланту и самоотдаче Матвея, наша платформа всегда выглядит стильно, современно и притягательно для ценителей моды и искусства. Его работы - это воплощение гармонии, эстетики и инновационного мышления.
                                    </p>
                                </TeamItem>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.partners} id="partner">
                <div className={base.container}>
                    <div className={styles.partnersInner}>
                        <h2 className={cn(base.title2, styles.partnersTitle)}>Партнеры проекта</h2>

                        <div className={styles.partnersContentWrapper}>
                            <div className={styles.partnersContent}>
                                <img
                                    src="/assets/img/partners-bg.svg"
                                    alt="partnersbg"
                                    className={styles.partnersBg}
                                />
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns:'1fr 1fr 1fr 1fr ',
                                        gap: '10px',
                                        height:'100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        botton: 0,
                                    }}
                                >
                                    {!isLoading &&
                                        accaunts &&
                                        accaunts.results &&
                                        accaunts.results.map((account, index) => {
                                            const {
                                                id,
                                                logo,
                                                name,
                                                description,
                                                link_instagram: linkInstagram,
                                                link_telegram: linkTelegram,
                                                link_vk: linkVk,
                                                link_yoursite: linkYoursite,
                                                link_youtube: linkYoutube,
                                            } = account;
                                            return (
                                                <div
                                                    className={cn(
                                                        styles.partnersItem,
                                                        styles[`partner${index + 1}`],
                                                    )}
                                                    key={index}
                                                >
                                                    <Link to={`/brandnew/${id}`}>
                                                        <img
                                                            src={getImageBacketPath(logo)}
                                                            alt="partner"
                                                            className={styles.partnersItemImg}
                                                        />
                                                    </Link>

                                                    <div
                                                        className={styles.brandItemInfoPopupWrapper}
                                                    >
                                                        <div className={styles.brandItemInfoPopup}>
                                                            <div
                                                                className={
                                                                    styles.brandItemInfoPopupContent
                                                                }
                                                            >
                                                                <p
                                                                    className={
                                                                        styles.brandItemInfoPopupName
                                                                    }
                                                                >
                                                                    {name}
                                                                </p>

                                                                <p
                                                                
                                                                    className={
                                                                        styles.brandItemInfoPopupText
                                                                    }
                                                                >
                                                                    {description}
                                                                </p>

                                                                <div
                                                                    className={
                                                                        styles.brandItemPopupSocialLinks
                                                                    }
                                                                >
                                                                    {linkVk &&
                                                                        linkVk !== 'null' && (
                                                                            <a
                                                                                href={linkVk}
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Vk />
                                                                            </a>
                                                                        )}
                                                                    {linkYoutube &&
                                                                        linkYoutube !== 'null' && (
                                                                            <a
                                                                                href={linkYoutube}
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Youtube />
                                                                            </a>
                                                                        )}
                                                                    {linkInstagram &&
                                                                        linkInstagram !==
                                                                            'null' && (
                                                                            <a
                                                                                href={linkInstagram}
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Instagram />
                                                                            </a>
                                                                        )}
                                                                    {linkTelegram &&
                                                                        linkTelegram !== 'null' && (
                                                                            <a
                                                                                href={linkInstagram}
                                                                                className={
                                                                                    styles.brandItemPopupSocialLink
                                                                                }
                                                                            >
                                                                                <Telegram2 />
                                                                            </a>
                                                                        )}
                                                                </div>
                                                                {linkYoursite &&
                                                                    linkYoursite !== 'null' && (
                                                                        <a
                                                                            href={linkYoursite}
                                                                            className={
                                                                                styles.brandItemSite
                                                                            }
                                                                        >
                                                                            <Share />
                                                                            {linkYoursite}
                                                                        </a>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>

                                <div className={styles.partnersWrap}>
                                    <div className={styles.partnersContentWrap}>
                                        {!isLoading &&
                                            accaunts &&
                                            accaunts.results &&
                                            accaunts.results.map((accaunt, index) => {
                                                const {
                                                    id,
                                                    logo,
                                                    name,
                                                    description,
                                                    link_instagram: linkInstagram,
                                                    link_telegram: linkTelegram,
                                                    link_vk: linkVk,
                                                    link_yoursite: linkYoursite,
                                                    link_youtube: linkYoutube,
                                                } = accaunt;
                                                return (
                                                    <div className={styles.partnersContentItem}>
                                                        <Link to={`/brandnew/${id}`}>
                                                            <img
                                                                src={getImageBacketPath(logo)}
                                                                alt="partner"
                                                                className={styles.partnersItemImg}
                                                            />
                                                        </Link>

                                                        <div
                                                            className={
                                                                styles.brandItemInfoPopupWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.brandItemInfoPopup
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.brandItemInfoPopupContent
                                                                    }
                                                                >
                                                                    <p
                                                                        className={
                                                                            styles.brandItemInfoPopupName
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </p>

                                                                    <p
                                                                        className={
                                                                            styles.brandItemInfoPopupText
                                                                        }
                                                                    >
                                                                        {description}
                                                                    </p>

                                                                    <div
                                                                        className={
                                                                            styles.brandItemPopupSocialLinks
                                                                        }
                                                                    >
                                                                        {linkVk &&
                                                                            linkVk !== 'null' && (
                                                                                <a
                                                                                    href={linkVk}
                                                                                    className={
                                                                                        styles.brandItemPopupSocialLink
                                                                                    }
                                                                                >
                                                                                    <Vk />
                                                                                </a>
                                                                            )}
                                                                        {linkYoutube &&
                                                                            linkYoutube !==
                                                                                'null' && (
                                                                                <a
                                                                                    href={
                                                                                        linkYoutube
                                                                                    }
                                                                                    className={
                                                                                        styles.brandItemPopupSocialLink
                                                                                    }
                                                                                >
                                                                                    <Youtube />
                                                                                </a>
                                                                            )}
                                                                        {linkInstagram &&
                                                                            linkInstagram !==
                                                                                'null' && (
                                                                                <a
                                                                                    href={
                                                                                        linkInstagram
                                                                                    }
                                                                                    className={
                                                                                        styles.brandItemPopupSocialLink
                                                                                    }
                                                                                >
                                                                                    <Instagram />
                                                                                </a>
                                                                            )}
                                                                        {linkTelegram &&
                                                                            linkTelegram !==
                                                                                'null' && (
                                                                                <a
                                                                                    href={
                                                                                        linkInstagram
                                                                                    }
                                                                                    className={
                                                                                        styles.brandItemPopupSocialLink
                                                                                    }
                                                                                >
                                                                                    <Telegram2 />
                                                                                </a>
                                                                            )}
                                                                    </div>
                                                                    {linkYoursite &&
                                                                        linkYoursite !== 'null' && (
                                                                            <a
                                                                                href={linkYoursite}
                                                                                className={
                                                                                    styles.brandItemSite
                                                                                }
                                                                            >
                                                                                <Share />
                                                                                {linkYoursite}
                                                                            </a>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    <p className={styles.partnersWrapTitle}>Партнеры</p>

                                    <p className={styles.partnersText}>Они доверяют нам</p>

                                    <div className={styles.partnersButtons}>
                                        <button
                                            className={cn(
                                                styles.partnersButton,
                                                styles.partnersButtonBlue,
                                            )}
                                            onClick={() => {
                                                scrollToHash('#start_cooperation');
                                            }}
                                        >
                                            Стать партнером
                                        </button>

                                        <button
                                            className={cn(base.orangeButton, styles.partnersButton)}
                                            onClick={() => {
                                                navigate(`/brandsnew`);
                                            }}
                                        >
                                            Смотреть всех
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Review /> {/* Перенес отзывы в отдельный компонент */}
            <section className={styles.faq} id="faq">
                <div className={base.container}>
                    <div className={styles.faqInner}>
                        <h2 className={cn(base.title2, styles.faqTitle)}>
                            Ответы на
                            <br />
                            частые вопросы
                        </h2>

                        <div className={styles.faqContent}>
                            <FaqItem question="Что обуславливает более высокую стоимость одежды с цифровым сертификатом?">
                                <div className={styles.faqItemPagginationContent}>
                                    {faqNumber === 1 && (
                                        <div className={styles.faqItemPagginationTextWrapper}>
                                            <p className={styles.faqItemText}>
                                                Более высокая стоимость одежды с цифровым сертификатом обусловлена рядом дополнительных преимуществ и возможностей, которые получает владелец такой вещи.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Во-первых, цифровой сертификат открывает эксклюзивный доступ к специальным товарам, услугам и мероприятиям от партнеров бренда. Это могут быть ограниченные коллекции, закрытые распродажи, VIP-обслуживание и многое другое.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Таким образом, покупатель не просто приобретает одежду, а целый набор дополнительных привилегий, которые значительно повышают ценность и полезность покупки.
                                            </p>
                                        </div>
                                    )}

                                    {faqNumber === 2 && (
                                        <div className={styles.faqItemPagginationTextWrapper}>
                                            <p className={styles.faqItemText}>
                                                Во-вторых, цифровой сертификат наделяет владельца особым статусом и причастностью к бренду.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Он получает доступ к закрытым клубным мероприятиям, эксклюзивным предложениям и гарантированным призам, что подчеркивает его особый статус среди других покупателей.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Это создает ощущение престижа и исключительности, что также находит отражение в более высокой стоимости.
                                            </p>
                                        </div>
                                    )}

                                    {faqNumber === 3 && <div className={styles.faqItemPagginationTextWrapper}>
                                        <p className={styles.faqItemText}>
                                            Наконец, цифровой сертификат выступает гарантом подлинности и происхождения одежды, защищая ее от подделок.
                                        </p>

                                        <p className={styles.faqItemText}>
                                            Это особенно важно для коллекционеров и ценителей, которые готовы платить больше за уверенность в аутентичности приобретаемой вещи.
                                        </p>

                                        <p className={styles.faqItemText}>
                                            Таким образом, цифровой сертификат не только наделяет одежду дополнительными функциями и преимуществами, но и выступает своеобразным знаком качества, что и обуславливает ее более высокую стоимость на рынке.
                                        </p>
                                    </div>}

                                    <div className={styles.faqItemPaggination}>
                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber === 1,
                                            })}
                                            onClick={() => setFaqNumber(1)}
                                        >
                                            1
                                        </button>

                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber === 2,
                                            })}
                                            onClick={() => setFaqNumber(2)}
                                        >
                                            2
                                        </button>

                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber === 3,
                                            })}
                                            onClick={() => setFaqNumber(3)}
                                        >
                                            3
                                        </button>
                                    </div>
                                </div>
                            </FaqItem>

                            <FaqItem question="Какие преимущества токенизированной одежды привлекают интерес покупателей?">
                                <div className={styles.faqItemPagginationContent}>
                                    {faqNumber2 === 1 && (
                                        <div className={styles.faqItemPagginationTextWrapper}>
                                            <p className={styles.faqItemText}>
                                                Одним из ключевых преимуществ токенизированной одежды, которое привлекает повышенный интерес покупателей, является ее уникальный и инновационный дизайн.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Многие модели могут быть оформлены с использованием QR-кодов или других элементов цифровой графики, что придает им особую визуальную привлекательность и технологичность.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Сканирование QR-кода на одежде вызывает у покупателей любопытство и желание узнать больше о связанном с ним цифровом сертификате.
                                            </p>
                                        </div>
                                    )}

                                    {faqNumber2 === 2 && (
                                        <div className={styles.faqItemPagginationTextWrapper}>
                                            <p className={styles.faqItemText}>
                                                Кроме того, владение токенизированной одеждой дает возможность стать частью эксклюзивного сообщества ценителей инноваций и технологий.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Покупатели получают возможность коллекционировать и демонстрировать перед окружающими свои цифровые сертификаты, что повышает их статус и престиж.
                                            </p>
                                        </div>
                                    )}

                                    {faqNumber2 === 3 && (
                                        <div className={styles.faqItemPagginationTextWrapper}>
                                            <p className={styles.faqItemText}>
                                                Многие покупатели также ценят возможность получать высокие рейтинги в социальных сетях за накопление редких и дорогостоящих сертификатов, а также шанс выиграть ценные призы и приглашения, доступные только для владельцев определенных токенов.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Таким образом, помимо практических преимуществ, токенизированная одежда обладает мощным символическим значением, позволяя покупателям выразить свою причастность к технологическим инновациям и продемонстрировать свой статус в обществе.
                                            </p>
                                        </div>
                                    )}

                                    <div className={styles.faqItemPaggination}>
                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber2 === 1,
                                            })}
                                            onClick={() => setFaqNumber2(1)}
                                        >
                                            1
                                        </button>

                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber2 === 2,
                                            })}
                                            onClick={() => setFaqNumber2(2)}
                                        >
                                            2
                                        </button>

                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber2 === 3,
                                            })}
                                            onClick={() => setFaqNumber2(3)}
                                        >
                                            3
                                        </button>
                                    </div>
                                </div>
                            </FaqItem>

                            <FaqItem question="Как цифровой сертификат гарантирует подлинность и защищает от контрафакта?">
                                <div className={styles.faqItemPagginationContent}>
                                    {faqNumber3 === 1 && (
                                        <p className={styles.faqItemText}>
                                            Ключевой механизм защиты заключается в неразрывной связи между цифровым сертификатом и физической вещью. Каждая единица одежды имеет уникальный QR-код, который напрямую связан с ее цифровым сертификатом. При покупке, этот QR-код передается новому владельцу вместе с одеждой.
                                        </p>
                                    )}

                                    {faqNumber3 === 2 && (
                                        <p className={styles.faqItemText}>
                                            После приобретения, владелец регистрирует цифровой сертификат на специальной платформе CheckBrand. В его профиле фиксируется факт владения данным сертификатом и связанной с ним вещью. Теперь любой желающий может просканировать QR-код на одежде и убедиться, что она принадлежит именно этому владельцу.
                                        </p>
                                    )}

                                    {faqNumber3 === 3 && <p className={styles.faqItemText}>
                                        Важно отметить, что до момента регистрации на CheckBrand, цифровой сертификат не имеет владельца. Попытки подделать или скопировать QR-код будут сразу выявлены, так как сканирование покажет, что сертификат не закреплен ни за кем.    
                                    </p>}

                                    {faqNumber3 === 4 && (
                                        <div className={styles.faqItemPagginationTextWrapper}>
                                            <p className={styles.faqItemText}>
                                                Таким образом, цифровой сертификат становится неотъемлемой частью подлинной вещи, гарантируя ее происхождение и защищая от контрафакта. Более того, сама платформа CheckBrand создает единую базу владельцев брендовых товаров, позволяя обществу самостоятельно выявлять случаи ношения подделок.
                                            </p>

                                            <p className={styles.faqItemText}>
                                                Простота и прозрачность этой системы защиты вызывает большой интерес у покупателей, которые ценят гарантии подлинности и возможность демонстрировать свою причастность к эксклюзивным вещам.
                                            </p>
                                        </div>
                                    )}

                                    <div className={styles.faqItemPaggination}>
                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber3 === 1,
                                            })}
                                            onClick={() => setFaqNumber3(1)}
                                        >
                                            1
                                        </button>

                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber3 === 2,
                                            })}
                                            onClick={() => setFaqNumber3(2)}
                                        >
                                            2
                                        </button>

                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber3 === 3,
                                            })}
                                            onClick={() => setFaqNumber3(3)}
                                        >
                                            3
                                        </button>

                                        <button
                                            className={cn(styles.faqItemPagginationItem, {
                                                [styles.active]: faqNumber3 === 4,
                                            })}
                                            onClick={() => setFaqNumber3(4)}
                                        >
                                            4
                                        </button>
                                    </div>
                                </div>
                            </FaqItem>

                            <FaqItem question="Какие шаги необходимо предпринять, чтобы начать сотрудничество?">
                                <div className={styles.faqItemPagginationContent}>
                                    <div className={styles.faqItemPagginationTextWrapper}>
                                        <ul className={styles.faqItemUl}>
                                            <li>Свяжитесь с нами любым удобным способом (по телефону, электронной почте, через форму на сайте) и расскажите о потребностях и задачах Вашего бренда.</li>
                                            <li>Мы проанализируем Ваш запрос и предложим оптимальные решения для токенизации Вашей брендовой продукции, а также обсудим условия и стоимость.</li>
                                            <li>После согласования всех деталей мы заключим договор и приступим к процессу токенизации Ваших изделий.</li>
                                            <li>На протяжении всего сотрудничества мы будем на связи, чтобы оперативно решать возникающие вопросы.</li>
                                            <li>По завершении работ мы предоставим Вам отчет и будем рады дальнейшему взаимодействию.</li>
                                        </ul>

                                        <p className={styles.faqItemText}>
                                            Будем рады ответить на любые дополнительные вопросы!
                                        </p>
                                    </div>
                                </div>
                            </FaqItem>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.collab}>
                <div className={base.container}>
                    <div id="start_cooperation" className={styles.collabInner}>
                        <h2 className={cn(base.title2, styles.collabTitle)}>
                            Начать
                            <br />
                            сотрудничество
                        </h2>

                        <CollabForm />
                    </div>
                </div>
            </section>
            <ModalNew active={orderThx} setActive={setOrderThx}>
                <div className={styles.modalSuccess}>
                    <Check3 />

                    <p className={cn(base.title2, styles.collabSuccessTitle)}>
                        Ваша заявка принята
                    </p>

                    <p className={styles.modalSuccessText}>
                        Менеджер свяжется с вами в течение 30 мин <span>(в рабочее время)</span>
                    </p>
                </div>
            </ModalNew>
            <ModalNew active={testModal} setActive={setTestModal}>
                <p className={cn(base.title2, styles.orderTitle)}>Оформление покупки</p>

                <div className={styles.orderInfo}>
                    <div className={styles.orderInfoTarif}>
                        <div className={styles.modalTarifItemIconInner}>
                            <div className={styles.tarifItemIcon}>
                                <Diamond />
                            </div>
                        </div>

                        <div className={styles.modalTarifInner}>
                            <p className={styles.modalTarifTitle}>Тариф:</p>
                            <p className={styles.modalTarifName}>{selectedTariff.name}</p>
                        </div>
                    </div>

                    <div className={styles.modalTarifInner}>
                        <p className={styles.modalTarifTitle}>Количество шт.:</p>
                        <p className={styles.modalTarifName}>{selectedTariff.count}</p>
                    </div>

                    <div className={styles.modalTarifInner}>
                        <p className={styles.modalTarifTitle}>Стоимость:</p>
                        <p className={cn(styles.modalTarifName, styles.blue)}>
                            {new Intl.NumberFormat('ru-RU').format(selectedTariff.price)} ₽
                        </p>
                    </div>
                </div>

                <div className={styles.orderContent}>
                    <p className={styles.orderSubtext}>Способ оплаты:</p>

                    <div className={styles.orderMethods}>
                        <div
                            className={cn(styles.orderMethod, {
                                [styles.active]: paymentMerhod === 'card',
                            })}
                            onClick={() => setPaymentMethod('card')}
                        >
                            <div className={styles.orderMethodIconInner}>
                                <Card />
                            </div>

                            <p>карта</p>
                        </div>
                    </div>

                    <p className={styles.orderSubtext}>Адрес электронной почты:</p>

                    <div className={styles.orderInputInner}>
                        <input
                            type="text"
                            placeholder="Введите e-mail"
                            className={styles.orderEmail}
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                        />

                        <div className={styles.orderInputCheckboxes}>
                            <div className={styles.orderInputCheckbox}>
                                <input
                                    id="agree"
                                    type="checkbox"
                                    className={styles.orderInputCheckboxInput}
                                    value={valueOrderCheckbox.offer}
                                    onChange={(e) =>
                                        setValueOrderCheckbox((prev) => ({
                                            ...prev,
                                            offer: !prev.offer,
                                        }))
                                    }
                                />

                                <label htmlFor="agree" className={styles.orderInputCheckboxLabel}>
                                    Я принимаю условия <a href="#">Договора оферты</a>
                                </label>
                            </div>

                            <div className={styles.orderInputCheckbox}>
                                <input
                                    id="agree2"
                                    type="checkbox"
                                    className={styles.orderInputCheckboxInput}
                                    value={valueOrderCheckbox.conditions}
                                    onChange={(e) =>
                                        setValueOrderCheckbox((prev) => ({
                                            ...prev,
                                            conditions: !prev.conditions,
                                        }))
                                    }
                                />

                                <label htmlFor="agree2" className={styles.orderInputCheckboxLabel}>
                                    Я согласен на обработку моих персональных данных в соответствии
                                    с <a href="#">Условиями</a>
                                </label>
                            </div>
                        </div>
                    </div>

                    <button
                        className={cn(base.orangeButton, styles.orderButton)}
                        onClick={handlePayment}
                        disabled={
                            !emailRegex.test(userEmail) ||
                            !valueOrderCheckbox.offer ||
                            !valueOrderCheckbox.conditions
                        }
                    >
                        Перейти к оплате
                    </button>

                    <div className={styles.orderText}>
                        <div className={styles.orderTextContent}>
                            Приобретая цифровые сертификаты на нашей платформе, Вы не только получаете подлинное подтверждение уникальности Ваших брендовых вещей, но и инвестируете в будущее моды. Ваш выбор демонстрирует ответственность бренда и заботу о покупателях. Завершите оплату и станьте частью нашего сообщества токенизации - вместе мы создаем новую эру прозрачности и доверия в индустрии.
                        </div>
                    </div>
                </div>
            </ModalNew>
        </div>
    );
};

export default MainNew;
