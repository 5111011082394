import React from 'react';

import css from './index.module.css';

import ActionMenu from '../../../components/ActionMenu';

const AccountItem = ({data, id}) => {
    const { page, avatar, brands, title1, description, title2, position, url, action = false } = data;

    return(
        <div className="stats__item--values">
            <div className="stats__item--item stats__item--status admins__item--wrap">
                <p className="whithdraw__index--mobile">
                    {id}
                </p>

                <div className="stats__item--avatar--inner withdraw__item">
                    {!avatar && <img
                        src={avatar}
                        alt="avatar"
                        className="stats__item--avatar"
                    />}
                </div>

                <div className="stats__item--wrapper withdraw__item">
                    <p className="withdraw__item--collection--name">{page.name}</p>
                </div>
            </div>

            <div className="stats__item--item withdraw__item--text admins__item--wrap off700">
                {brands}
            </div>

            <div className="stats__item--item admins__item--wrap whithdraw__flex">
                <p className="stats__item--value regular">{title1}</p>
            </div>

            <div className="stats__item--item admins__item--wrap off998 activeoff1600">
                <p className="opportunity__desc">{description}</p>
            </div>

            <div className="stats__item--item stats__item--value admins__item--wrap off700 activeoff1200">
                {title2}
            </div>

            <div className="withdraw__item--text admins__item--wrap off1200 activeoff1600">
                {position}
            </div>

            <div className="stats__item--item withdraw__item--text admins__item--wrap off1200 activeoff1600">
                {url}
            </div>

            <div className="stats__item--item stats__item--value admins__item--wrap">
                <ActionMenu actions={[
                    {
                        text: "Edit",
                        action: () => {}
                    },
                    {
                        text: "Delete",
                        action: () => {}
                    },
                    {
                        text: "Copy Wallet",
                        action: () => {}
                    }
                ]} id={id} />
            </div>
        </div>
    )
}

export default AccountItem;