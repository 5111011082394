import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import NOTIFICATION_TYPES from '../../../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../../../context/NotificationContext';
import { useGetTokensQuery } from '../../../../redux/api/dataService';
import { normilizeError } from '../../../../utils/http/normilizeError';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import { useGetFilteredTokensQuery } from '../../../../redux/api/handleService';
import { FiltersContext, SORT_BY_OPTIONS } from './FiltersContext';
import useDebounce from '../../../../hooks/useDebounce';
import { generateUniqueHash } from '../generateUniqueHash';

const DEFAULT_STATE = {
    collectionTokensPage: 1,
    collectionTokensCount: 0,
    collectionTokens: [],
    originalCollectionTokens: [],
    collection: {},
    bookedTokensIds: [],
    page_id: null,
    query: '',
    sortBy: SORT_BY_OPTIONS.DESCENDING.value,
    status: [],
    levels_stats_ids: [],
    properties_id: [],
    filterBy: null,
};

const COLLECTION_TOKENS_PER_PAGE = 12;

const CertificateContext = React.createContext({});
 
const CertificateContextProvider = (props) => {
    const { children, id } = props;

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const {
        state: { activeStatusesFilter, activePropertiesFilter, activeLevelStatsFilter, sortBy, paid },
        actions: { dropAllFilters },
        debouncedQuery,
    } = useContext(FiltersContext);

    const [state, setState] = useState(DEFAULT_STATE);

    const isCollectionTokensRefetching = useRef(false);
    let storedHash = localStorage.getItem('uniqueHash');
    
    if (!storedHash) {
      storedHash = generateUniqueHash();
      localStorage.setItem('uniqueHash', storedHash);
    }

    const {
        data: collectionTokens,
        error: getTokensError,
        isLoading: isTokensLoading,
        isFetching: isFetchingLoading,
        refetch: refetchCollectionTokens,
    } = useGetFilteredTokensQuery(
        {
            page: state.collectionTokensPage,
            pageSize: COLLECTION_TOKENS_PER_PAGE,
            pageId: state.page_id,
            queryIndex: storedHash,
            paid: state.filterBy,
            query: state.query,
            status: state.status,
            levels_stats_ids: state.levels_stats_ids,
            properties_id: state.properties_id,
        }
    );


    const onNextPageCollectionsTokensHandler = useDebouncedCallback(
        () => {
            setState((p) => {
                if (
                    p.collectionTokensCount > p.collectionTokens.length &&
                    !isCollectionTokensRefetching.current
                ) {
                    isCollectionTokensRefetching.current = true;

                    return {
                        ...p,
                        collectionTokensPage: p.collectionTokensPage + 1,
                    };
                }
                return p;
            });
        },
        [],
        250,
    );

    const refetchCollectionTokensHandler = useCallback(() => {
        dropAllFilters();

        setState((p) => {
            if (p.collectionTokensPage === 1) {
                refetchCollectionTokens();
                return p;
            }

            return {
                ...p,
                collectionTokensPage: 1,
            };
        });
    }, []);


    useEffect(() => {
        setState((p) => {
            return {
                ...p,
                collectionTokensPage: 1,
            };

        });
    }, [paid]);

    useEffect(() => {
        if (collectionTokens && collectionTokens.results) {
            setState((p) => ({
                ...p,
                collectionTokensCount: collectionTokens.count,
                originalCollectionTokens: collectionTokens.page === 1
                    ? collectionTokens.results
                    : [...p.collectionTokens, ...collectionTokens.results],
                collectionTokens:
                    collectionTokens.page === 1
                        ? collectionTokens.results
                        : [...p.collectionTokens, ...collectionTokens.results],
            }));

            isCollectionTokensRefetching.current = false;
        }
    }, [collectionTokens]);

    useEffect(() => {
        if (getTokensError) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(getTokensError),
            });
        }
    }, [getTokensError]);

    useDebounce(
        () => {
            setState((p) => ({
                ...p,
                collectionTokensPage: 1,
                status: activeStatusesFilter,
                properties_id: activePropertiesFilter.map((el) => el.id),
                levels_stats_ids: activeLevelStatsFilter.map((el) => el.id),
                query: debouncedQuery,
                sortBy,
            }));
        },
        [
            activeStatusesFilter,
            activePropertiesFilter,
            activeLevelStatsFilter, 
            debouncedQuery,
            sortBy,
        ],
        200,
    );
    const handlePage = (pageId) => {
        setState(prevState => ({
            ...prevState,
            page_id: pageId === "all" ? null : pageId,
            collectionTokensPage: 1
        }));
    };
    const handleFilter = (filter) => {
        setState(prevState => ({
            ...prevState,
            filterBy: filter,
        }));
    };

    const actions = {
        // checkCollectionTokens,
        onNextPageCollectionsTokensHandler,
        onCollectionTokensRefetch: refetchCollectionTokensHandler,
        handlePage,
        handleFilter
    };


    return (
        <CertificateContext.Provider
            value={ {
                state,
                actions,
                sortBy,
                isTokensLoading,
                isFetchingLoading,
            } }
        >
            { children }
        </CertificateContext.Provider>
    );
};

export { CertificateContext, CertificateContextProvider };
