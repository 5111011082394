import React, {
    useMemo,
    useRef,
    useState,
    useLayoutEffect,
    useCallback,
    useEffect,
    useContext,
} from 'react';
import { format } from 'date-fns';
import { cnb } from 'cnbuilder';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DeleteEntityDialog from '../../../components/DeleteEntityDialog/DeleteEntityDialog';
import { onOpen, onClose } from '../../../redux/dialogs/deleteEntityDialog';
import WithImageCell from '../../../common/Table/cells/WithImageCell';
import useDebounce from '../../../hooks/useDebounce';
import {
    useGetFilteredCollectionQuery,
    useHideCollectionMutation,
    useUpdateCollectionStatusMutation,
} from '../../../redux/api/dataService';
import ActionsComponent from '../ActionsComponent';
import { CommonCell, ActionsCell } from '../../../common/Table/cells';
import { Table } from '../../../common/Table';
import { normilizeError } from '../../../utils/http/normilizeError';
import CenteredContainer from '../../../common/CenteredContainer';
import Loader from '../../../common/Loader';
import {
    EDIT_COLLECTION_PAGE,
    STATISTICS_COLLECTION_PACKS_LIST,
} from '../../../const/http/CLIENT_URLS';
import { NotificationContext } from '../../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import {
    onClose as onConfirmDialogClose,
    onOpen as onConfirmDialogOpen,
} from '../../../redux/dialogs/confirmAplicationDialog';

import css from '../Statistics.module.css';
import CollectionStatusChange from './CollectionStatusChange';
import TableCell from '../../../components/TableRow/TableCell';
import TableRow from '../../../components/TableRow';
import { BREAKPOINTS, BREAKPOINTS_ACTIVE } from '../../../const/breakpoints/BREAKPOINTS';
import FilterTable from '../../../components/FilterTable';

const HEDER_CELLS = {
    STATUS: 'Status',
    NAME: 'Name',
    ITEMS: 'Items',
    PROFIT: 'Profit',
    BLOCKCHAIN: 'Blockchain',
    CREATOR_FEE: 'Creator fee',
    LISTED: 'Listed',
    ACTION: 'Action',
    STATE_CHANGE_ACTION: 'Status',
};

const BODY_CELLS = {
    STATUS: 'status',
    NAME: 'name',
    ITEMS: 'items_count',
    PROFIT: 'profit',
    BLOCKCHAIN: 'blockchain',
    CREATOR_FEE: 'creator_fee',
    LISTED: 'created_at',
    ACTION: 'action',
    STATE_CHANGE_ACTION: 'Status',
};

const HEADER_CELLS_ARR = [
    { label: HEDER_CELLS.STATUS, xs: 2 },
    { label: HEDER_CELLS.NAME, xs: 4 },
    { label: HEDER_CELLS.ITEMS, xs: 2 },
    { label: HEDER_CELLS.PROFIT, xs: 2 },
    { label: HEDER_CELLS.BLOCKCHAIN, xs: 2 },
    { label: HEDER_CELLS.CREATOR_FEE, xs: 2 },
    { label: HEDER_CELLS.LISTED, xs: 2 },
    { label: '', xs: 2, isAction: true },
    { label: '', xs: 2, isAction: true },
];

const TABLE_BOTTOM_MARGIN = 20;

const CollectionsList = () => {
    const { accountId } = useParams();

    const { isOpen, id: deletedCollectionId } = useSelector((state) => state.deleteEntityDialog);
    const { isOpen: isConfirmeApplicationOpen, id: confimedAplicationId } = useSelector(
        (state) => state.confirmAplicationDialog,
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const beforeTableDiv = useRef(null);

    const [collections, setCollections] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [tableHeight, setTableHeight] = useState(0);

    const { data, error, isLoading, isFetching, refetch } = useGetFilteredCollectionQuery(
        {
            page,
            pageSize: rowsPerPage,
            accountId,
        },
    );

    const [
        hideCollection,
        {
            isSuccess,
            isLoading: isDeletationProccessing,
            error: hideCollectionError,
            reset: resetDeletationState,
        },
    ] = useHideCollectionMutation();

    const [
        changeCollectionStatusRS,
        {
            isLoading: isCollectionStatusChangedLoading,
            isSuccess: isCollectionStatusChangedSuccessfuly,
            error: collectionStatusChangedError,
            reset: collectionStatusChangeReset,
        },
    ] = useUpdateCollectionStatusMutation();

    const onEditHandler = useCallback((id) => {
        navigate(EDIT_COLLECTION_PAGE({ id }));
    }, []);

    const onDeleteHandler = useCallback((id) => {
        hideCollection({ id });
    }, []);

    const onDeleteCollection = useCallback((id) => {
        dispatch(
            onOpen({
                id,
                onDelete: onDeleteHandler,
                title: 'Are you sure you want to delete collection?',
            }),
        );
    }, []);

    const closeDialogHandler = useCallback(() => {
        dispatch(onClose());
    }, []);

    const closeConfirmDialogHandler = useCallback(() => {
        dispatch(onConfirmDialogClose());
    }, []);

    const onCollectionStatusChangeHandler = useCallback((id, status) => {
        changeCollectionStatusRS({ id, data: { status } });
    }, []);

    const onChangeCollectionStatus = useCallback((id, newStatus) => {
        dispatch(
            onConfirmDialogOpen({
                id,
                onConfirm: (id) => {
                    onCollectionStatusChangeHandler(id, newStatus);
                },
                title: 'Are you sure you want to change collection status?',
            }),
        );
    }, []);

    const headerCellsArray = useMemo(() => {
        return HEADER_CELLS_ARR.map((cell) => ({
            label: cell.label,
            labelComponent: (
                <div className={css.headingCellBox}>
                    <p>{cell.label}</p>
                </div>
            ),
            xs: cell.xs,
        }));
    }, []);

    const bodyRowsArray = useMemo(() => {
        if (!collections || !collections.length) {
            return [];
        }
        const columns = Object.values(BODY_CELLS);

        const bodyRows = collections.map((c) => {
            const cellsArray = [];

            columns.forEach((name) => {
                switch (name) {
                    case BODY_CELLS.NAME:
                        cellsArray.push({
                            label: name,
                            xs: 4,
                            component: (
                                <WithImageCell
                                    classes={{
                                        cellRoot: cnb(css[`${name}Cell`], css.cellRoot),
                                        imageRoot: css.imageRoot,
                                    }}
                                    imageUrl={c.logo}
                                    value={c.name}
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.ACTION:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            disableRowClickEvent: true,
                            component: (
                                <ActionsCell
                                    classes={{ cellRoot: css.buttonsCell }}
                                    actionsComponent={
                                        <ActionsComponent
                                            id={c.id}
                                            onEdit={onEditHandler}
                                            onDelete={onDeleteCollection}
                                        />
                                    }
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.STATE_CHANGE_ACTION:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            disableRowClickEvent: true,
                            horizontalPosition: 'center',
                            component: (
                                <ActionsCell
                                    classes={{ cellRoot: css.buttonsCell }}
                                    actionsComponent={
                                        <CollectionStatusChange
                                            status={c.status}
                                            onChangeCollectionStatus={onChangeCollectionStatus}
                                            id={c.id}
                                        />
                                    }
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.BLOCKCHAIN:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={String(c.blockchain.name)}
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.PROFIT:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={`${Number(c.profit)} ${
                                        c.payment_tokens.length ? c.payment_tokens[0].name : '---'
                                    }`}
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.CREATOR_FEE:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={`${Number(c.creator_fee || 0).toFixed(4)}`}
                                />
                            ),
                        });
                        return;
                    case BODY_CELLS.LISTED:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={format(new Date(c.created_at), 'dd MMM yyyy')}
                                />
                            ),
                        });
                        return;
                    default:
                        cellsArray.push({
                            label: name,
                            xs: 2,
                            component: (
                                <CommonCell
                                    classes={{ cellRoot: cnb(css[`${name}Cell`], css.cellRoot) }}
                                    value={c[name]}
                                />
                            ),
                        });
                }
            });

            return {
                id: c.id,
                linkUrl: STATISTICS_COLLECTION_PACKS_LIST({ accountId, collectionId: c.id }),
                items: cellsArray,
            };
        });

        return bodyRows;
    }, [collections, accountId]);

    const handleChangePage = useCallback((_, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(1);
    };

    useDebounce(
        () => {
            refetch({
                page,
                pageSize: rowsPerPage,
                accountId,
            });
        },
        [page, rowsPerPage, accountId],
        500,
    );

    useLayoutEffect(() => {
        if (beforeTableDiv.current) {
            const offset = beforeTableDiv.current.getBoundingClientRect();
            setTableHeight(
                Number(window.innerHeight || 0) -
                    (Number(offset.bottom || 0) + TABLE_BOTTOM_MARGIN),
            );
        }
    }, [collections]);

    useEffect(() => {
        if (hideCollectionError) {
            closeDialogHandler();
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(hideCollectionError),
            });
        }
    }, [hideCollectionError]);

    useEffect(() => {
        if (isSuccess && deletedCollectionId) {
            setCollections((p) => p.filter((c) => c.id !== deletedCollectionId));

            closeDialogHandler();
            resetDeletationState();
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Collection deleted successfuly',
            });
        }
    }, [isSuccess, deletedCollectionId]);

    useEffect(() => {
        if (data && data.results) {
            setCollections(data.results);
            setCount(data.count);
        }
    }, [data]);

    useEffect(() => {
        if (isCollectionStatusChangedSuccessfuly && confimedAplicationId) {
            refetch({
                page,
                pageSize: rowsPerPage,
                sort: 'created_at',
            });
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Collection status updated successfuly',
            });
            closeConfirmDialogHandler();
            collectionStatusChangeReset();
        }
    }, [isCollectionStatusChangedSuccessfuly, confimedAplicationId, page, rowsPerPage]);

    useEffect(() => {
        if (collectionStatusChangedError && confimedAplicationId) {
            addNotification({
                type: NOTIFICATION_TYPES.ERROR,
                text: normilizeError(collectionStatusChangedError),
            });
            closeConfirmDialogHandler();
        }
    }, [collectionStatusChangedError, confimedAplicationId]);

    if (isLoading) {
        return (
            <CenteredContainer>
                <Loader />
            </CenteredContainer>
        );
    }

    if (error || isLoading) {
        return (
            <div>
                <span style={{ color: 'white' }}>{normilizeError(error)}</span>
            </div>
        );
    }

    return (
        <>
            <div className="statistics__inner">
                <h2 className="statistics__title">Collections</h2>
                <div ref={beforeTableDiv} className={css.beforeTableElement} />
                <Table
                    tableHeight={tableHeight}
                    tableInfo={bodyRowsArray}
                    headerInfo={headerCellsArray}
                    count={count}
                    page={page - 1}
                    isLoading={isFetching}
                    isNoResultFound={!collections.length}
                    notFoundPlug={<div className={css.notFoundContainer}>No results found</div>}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            {isOpen && (
                <DeleteEntityDialog
                    open={isOpen}
                    isDeletationProccessing={isDeletationProccessing}
                    onClose={closeDialogHandler}
                />
            )}

            {isConfirmeApplicationOpen && (
                <ConfirmDialog
                    open={isConfirmeApplicationOpen}
                    isConfimationProccessing={isCollectionStatusChangedLoading}
                    onClose={closeConfirmDialogHandler}
                />
            )}
        </>
    );
};

export default React.memo(CollectionsList);
