export const detectDeviceUrl = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
     
    // Проверяем, является ли устройство мобильным
    const isMobile = /android|iphone|ipad|ipod/i.test(userAgent);

    if (isMobile) {
        if (/android/i.test(userAgent)) {
            return 'https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&pli=1';
        } else if (/iphone|ipad|ipod/i.test(userAgent)) {
            return 'https://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409?mt=8';
        } else {
            return 'https://trustwallet.com/ru';
        }
    } else {
        return 'https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph?pli=1';
    }
};
