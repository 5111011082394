exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OverflowTooltip_wrapper__2xwn0 {\n    white-space: wrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "OverflowTooltip_wrapper__2xwn0"
};