import React, { memo, useState, useEffect, useMemo } from 'react';

export const SendCodeButton = memo(({ type, data, codeError, updateError, setUpdateError }) => {
    const [seconds, setSeconds] = useState(60);
    const [isActive, setIsActive] = useState(false);
    const accessToken = localStorage.getItem('access_token');

    useEffect(() => {
        let timer;

        if (isActive && seconds > 0) {
            setIsActive(true);
            timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isActive, seconds]);

    useEffect(() => {
        if (seconds === 0) {
            setIsActive(false);
        }
    }, [seconds]);

    const handlefetchCode = () => {
        setSeconds(60);
        setIsActive(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                [`${type}`]: String(data).trim(),
            }),
        }).then((res) => {
            if (Number(res.status) === 200) {
                setUpdateError(false);
            } else {
                setUpdateError(true);
                setIsActive(false)
            }
        });
    };

    return (
        <button
            style={{
                backgroundColor: '#E5E8EB0A',
                color: '#E5E8EBCC',
                border: 0,
                outline: 0,
                padding: '10px',
                borderRadius: '6px',
            }}
            onClick={handlefetchCode}
            disabled={isActive}
        >
            {isActive ? seconds : 'Отправить'}
        </button>
    );
});
