import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import styles from '../index.module.css';
import Light from '../../../icons/Light';

const RangeItem = ({ min, max, state, setState, tariffs }) => {
    const [value, setValue] = useState(min);
    const [price, setPrice] = useState(getTariffInfo(min, tariffs).currentPrice);
    const [nextTariff, setNextTariff] = useState(getTariffInfo(min, tariffs).nextTariff || null);
    const [totalPrice, setTotalPrice] = useState(min * price);

    const debounceTimeout = useRef(null);

    useEffect(() => {
        if (state && state.count) {
            setValue(state.count);
        }
    }, [state]);

    useEffect(() => {
        const tariffInfo = getTariffInfo(value, tariffs);
        setPrice(tariffInfo.currentPrice);
        setNextTariff(tariffInfo.nextTariff);
        setTotalPrice((value * tariffInfo.currentPrice).toFixed(0));
    }, [value, tariffs]);

    const handleChange = (newValue) => {
        setValue(newValue);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            const tariffInfo = getTariffInfo(newValue, tariffs);
            setPrice(tariffInfo.currentPrice);
            setNextTariff(tariffInfo.nextTariff);
            setTotalPrice((newValue * tariffInfo.currentPrice).toFixed(0));

            if (setState) {
                setState({ count: newValue, totalPrice: Number((newValue * tariffInfo.currentPrice).toFixed(0)) });
            }
        }, 200);
    };

    const blurInput = () => {
        let curValue = value;

        if (curValue === '' || curValue < min) {
            curValue = min;
        } else if (curValue > max) {
            curValue = max;
        }

        setValue(curValue);

        debounceTimeout.current = setTimeout(() => {
            const tariffInfo = getTariffInfo(curValue, tariffs);
            setPrice(tariffInfo.currentPrice);
            setNextTariff(tariffInfo.nextTariff);
            setTotalPrice((curValue * tariffInfo.currentPrice).toFixed(0));

            if (setState) {
                setState({ count: curValue, totalPrice: Number((curValue * tariffInfo.currentPrice).toFixed(0)) });
            }
        }, 200);
    };

    return (
        <>
            <div className={ styles.tarifSettingsContent }>
                <p className={ styles.tarifSettingsCert }>Выберите количество сертификатов:</p>

                <div className={ styles.tarifSettingsInputInner }>
                    <input
                        type="number"
                        className={ styles.tarifSettingsInput }
                        value={ value }
                        onChange={ e => handleChange(e.target.value === '' ? '' : Number(e.target.value)) }
                        onBlur={ blurInput }
                        min={ min }
                        max={ max }
                    />

                    <input
                        type="range"
                        className={ styles.tarifSettingsInputRange }
                        min={ min }
                        max={ max }
                        value={ value || min }
                        onChange={ e => handleChange(Number(e.target.value)) }
                    />
                </div>

                <div className={ styles.tarifSettingsInfo }>
                    <div className={ styles.tarifSettingsInfoItem }>
                        <p className={ styles.tarifSettingsItemName }>Цена за 1 шт.:</p>
                        <p className={ styles.tarifSettingsItemValue }>{ price } ₽</p>
                    </div>

                    <div className={ styles.tarifSettingsInfoItem }>
                        <p className={ cn(styles.tarifSettingsItemName, styles.bold) }>Итого:</p>
                        <p className={ cn(styles.tarifSettingsItemValue, styles.blue) }>{ totalPrice } ₽</p>
                    </div>
                </div>
            </div>

            { nextTariff && (
                <div className={ styles.tarifSettingsItem }>
                    <Light />
                    <div className={ styles.tarifSettingsItemText }>
                        <p className={ styles.tarifSettingsItemName }>
                            При заказе от { new Intl.NumberFormat('ru-RU').format(nextTariff.count) } шт. цена за 1 шт. составит:
                        </p>
                        <p className={ styles.tarifSettingsItemValue }>{ nextTariff.price } ₽</p>
                    </div>
                </div>
            ) }

            <div className={ styles.tarifSettingsItem }>
                <Light />
                <div className={ styles.tarifSettingsItemText }>
                    <p className={ styles.tarifSettingsItemName }>Минимальный заказ:</p>
                    <p className={ styles.tarifSettingsItemValue }>{ min } шт.</p>
                </div>
            </div>
        </>
    );
};

function getTariffInfo(value, tariffs) {
    let currentPrice = 0;
    let nextTariff = null;

    for (let i = 0; i < tariffs.length; i++) {
        const { count, price } = tariffs[i];
        if (value >= count) {
            currentPrice = price;
            if (i + 1 < tariffs.length) {
                nextTariff = {
                    price: tariffs[i + 1].price,
                    count: tariffs[i + 1].count,
                };
            }
        }
    }

    if (value >= tariffs[tariffs.length - 1].count) {
        currentPrice = tariffs[tariffs.length - 1].price;
        nextTariff = null;
    }

    return { currentPrice, nextTariff };
}

export default RangeItem;
