import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import MenuMobile from '../MenuMobile';
import MenuMobileAdmin from '../MenuMobileAdmin';
import AuthElements from '../AuthElements';
import WalletMenu from '../WalletMenu';
import { pagesSelectors } from '../../redux/slices/pages';
import { STATS_MENU_ITEMS, CREATE_MENU_ITEMS, RESOURCES_MENU_ITEMS } from './const';
import DropLinkMenuItem from './DropLinkMenuItem';

import './index.css';
import TransferMenu from '../TransferMenu';
import {useEditProfileMutation} from "../../redux/api/userService";
import {setIsWelcomeModalActive} from "../../redux/slices/auth";
import {ContactsModal} from "../WelcomeModal/ContactsModal";
import {ConfirmEmailModal} from "../ConfirmEmailModal";
import {
    WithdrawalAndReturnConditions
} from '../../pages/TokenCard/modals/WithdrawalAndReturnConditions/WithdrawalAndReturnConditions';
import { TrustModal } from './modals/TrustModal';

const Header = (props) => {
    const [isConditionModalOpen, setIsConditionModalOpen] = useState(false)
    const [isOpenTrustModal, setIsOpenTrustModal] = useState(false)
    
    const { searchValue, onSearchValueChange, isAdminPage = false } = props;

    const pages = useSelector(pagesSelectors.selectAll);
    const dispatch = useDispatch()

    const sortedPages = useMemo(() => pages.sort((a, b) => a.number - b.number), [pages]);

    const [menu, setMenu] = useState(false);
    const [menuAdmin, setMenuAdmin] = useState(false);
    const [walletMenu, setWalletMenu] = useState(false);
    const [isConfirmedEmail, setIsConfirmedEmail] = useState(false)

    const email = useSelector(state => state.auth.email)
    const userName = useSelector(state => state.auth.userName)
    const isAuth = useSelector(state => state.auth.isAuth)
    const userRole = useSelector(state => state.auth.userRole)

    const navigate = useNavigate();

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accessToken = localStorage.getItem('access_token')

                if(accessToken) {
                    const url = `${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/my`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${encodeURIComponent(accessToken)}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    setUserData(data);
                }

            } catch (error) {
                console.error('Error fetching data:');
            }
        };

        fetchData();
    }, []);

    const emptySearch = () => {
        onSearchValueChange();
    }

    if (!pages) {
        return '';
    }

    return (
        <>
            <header className={`header${isAdminPage ? ' admin' : ''}`}>
                <div className="container">
                    <div className="header__inner">
                        <Link to="/" className="header__logo" onClick={() => {
                            setMenu(false);
                            // setTransferMenu(true);
                        }}>
                            <img
                                src="/assets/img/logo.svg"
                                alt="logo"
                                className="header__logo--img"
                            />
                        </Link>

                        <div className="header__search--inner">
                            <input
                                type="text"
                                value={searchValue}
                                className="input header__search"
                                placeholder="Search brand"
                                onChange={onSearchValueChange}
                            />

                            <img
                                src="/assets/img/search.svg"
                                alt="search"
                                className="header__search--icon"
                            />

                            {searchValue && (
                                <img
                                    src="/assets/img/cross2.svg"
                                    alt="cross"
                                    className="header__search--remove"
                                    onClick={emptySearch}
                                />
                            )}
                        </div>

                        <div className="header__wallet--inner">
                            {isAdminPage ? (
                                <nav className="header__nav header__admin--nav">
                                    <div className="header__admin--nav--link">
                                        <Link
                                            to="/admin/conclusions"
                                            className="header__admin--nav--drop--link"
                                        >
                                            Выводы
                                        </Link>
                                    </div>
                                    <div className="header__admin--nav--link">
                                        Control Panel
                                        <span className="header__admin--nav--drop">
                                            <Link
                                                to="/admin"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Control Panel
                                            </Link>

                                            <Link
                                                to="treasure"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Treasure
                                            </Link>

                                            <Link
                                                to="administrator"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Administrator
                                            </Link>

                                            <Link
                                                to="superadministrator"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Super Administrator
                                            </Link>
                                        </span>
                                    </div>

                                    <div className="header__admin--nav--link">
                                        Managers
                                        <span className="header__admin--nav--drop">
                                            <Link
                                                to="admins"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Administrators / Moderators
                                            </Link>

                                            <Link
                                                to="factorystores"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Factories / Stores
                                            </Link>

                                            <Link
                                                to="royalty"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Royalty recipients
                                            </Link>

                                            <Link
                                                to="whitelistes"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Whitelists
                                            </Link>
                                        </span>
                                    </div>

                                    <div className="header__admin--nav--link">
                                        Stats
                                        <span className="header__admin--nav--drop">
                                            <Link
                                                to="activity"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Activity
                                            </Link>

                                            <Link
                                                to="statistics"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Statistics
                                            </Link>

                                            <Link
                                                to="statistics2"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Statistics2
                                            </Link>

                                            <Link
                                                to="whitelist"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Whitelist
                                            </Link>

                                            <Link
                                                to="factory"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Factory
                                            </Link>

                                            <Link
                                                to="store"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Store
                                            </Link>

                                            <Link
                                                to="delivery"
                                                className="header__admin--nav--drop--link"
                                            >
                                                Delivery
                                            </Link>
                                        </span>
                                    </div>

                                    <div className="header__admin--nav--link">
                                        Create
                                        <span className="header__admin--nav--drop">
                                            {CREATE_MENU_ITEMS.map((item) => (
                                                <DropLinkMenuItem
                                                    key={item.to}
                                                    to={item.to}
                                                    label={item.label}
                                                />
                                            ))}
                                        </span>
                                    </div>
                                    <div className="header__admin--nav--link" onClick={() => navigate('modal')}>
                                        Модалки
                                    </div>

                                </nav>
                            ) : (
                              <>
                                  {isAuth && !email &&
                                    <div className="header__admin--nav--link" onClick={() => dispatch(setIsWelcomeModalActive(true))}>
                                        Контакты
                                    </div>
                                  }
                                  {userRole && <>
                                      <nav className="header__nav">
                                          <div className="header__admin--nav--link">
                                              Explore
                                              <span className="header__admin--nav--drop">
                                            {sortedPages.map((page) => (
                                              <a
                                                key={page.id}
                                                href={`/${page.url}`}
                                                className="header__admin--nav--drop--link"
                                              >
                                                  <img
                                                    src={page.icon || '/assets/img/car.svg'}
                                                    alt="icon"
                                                    className="header__admin--nav--drop--icon"
                                                  />

                                                  {page.name}
                                              </a>
                                            ))}
                                        </span>
                                          </div>

                                          <div className="header__admin--nav--link">
                                              Resources
                                              <span className="header__admin--nav--drop">
                                            {RESOURCES_MENU_ITEMS.map((item) => (
                                              <DropLinkMenuItem
                                                key={item.to}
                                                to={item.to}
                                                label={item.label}
                                              />
                                            ))}
                                        </span>
                                          </div>

                                          <Link to="/admin" className="header__nav--link">
                                              Admin
                                          </Link>
                                      </nav>
                                  </>}
                              </>

                            )}

                            {isAdminPage ? (
                                <AuthElements
                                    setActive={setWalletMenu}
                                    menu={menuAdmin}
                                    setMenu={setMenuAdmin}
                                />
                            ) : (
                                <AuthElements
                                    setActive={setWalletMenu}
                                    menu={menu}
                                    setMenu={setMenu}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </header>

            {isAdminPage ? (
                <MenuMobileAdmin active={menuAdmin} setActive={setMenuAdmin} />
            ) : (
                <MenuMobile active={menu} setActive={setMenu} setActiveConnect={setWalletMenu} />
            )}

            <WalletMenu
                active={walletMenu}
                setActive={setWalletMenu}
                setIsConditionModalOpen={setIsConditionModalOpen}
            />


            <ContactsModal setIsConfirmedEmail={setIsConfirmedEmail}/>
            {isConfirmedEmail &&
              <ConfirmEmailModal
                isConfirmedEmail={isConfirmedEmail}
                setIsConfirmedEmail={setIsConfirmedEmail}
                email={email}
              />
            }
            <WithdrawalAndReturnConditions
                isOpen={isConditionModalOpen}
                setIsOpen={setIsConditionModalOpen}
            />
            <TrustModal
                isOpen={isOpenTrustModal}
                setIsOpen={setIsOpenTrustModal}
            />
        </>
    );
};

export default React.memo(Header);
