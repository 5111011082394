import React, { useCallback } from 'react';
import { COLLECTION_STATUS } from '../../../const/collection/COLLECTION_STATUS';

import css from './CollectionStatusChange.module.css';

const CollectionStatusChange = (props) => {
    const { status, id, onChangeCollectionStatus } = props;

    const onStartBookingHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            onChangeCollectionStatus(id, COLLECTION_STATUS.BOOK);
        },
        [id],
    );

    const onStartMintingHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            onChangeCollectionStatus(id, COLLECTION_STATUS.MINT_1);
        },
        [id],
    );

    if (status === COLLECTION_STATUS.STOP) {
        return (
            <button
                onClick={onStartBookingHandler}
                className={`button ${css.cancelBookApplicationBtn}`}
            >
                Start book
            </button>
        );
    }

    if (status === COLLECTION_STATUS.BOOK) {
        return (
            <button
                onClick={onStartMintingHandler}
                className={`button ${css.cancelBookApplicationBtn}`}
            >
                Start mint_1
            </button>
        );
    }

    return <span style={{ color: 'white' }}>---</span>;
};

export default React.memo(CollectionStatusChange);
