import React from 'react';
import cn from 'classnames';

import styles from '../index.module.css';
import base from '../../../styles/base.module.css';

import ModalNew from '../../../common/ModalNew';
import Vk from '../../../icons/Vk';
import { Instagram } from '@mui/icons-material';
import Telegram2 from '../../../icons/Telegram2';

const TeamItem = ({
    name,
    role,
    image,
    vkLink = '#',
    instagramLink = '#',
    telegramLink = '#',
    children,
}) => {
    const [teamModal, setTeamModal] = React.useState(false);

    return (
        <>
            <div className={styles.teamSlide} onClick={() => setTeamModal(true)}>
                <img src={image} alt="team" className={styles.teamSlideImg} />

                <div className={styles.teamSlideInfo}>
                    <p className={styles.teamSlideInfoName}>{name}</p>

                    <p className={styles.teamSlideInfoRole}>{role}</p>

                    <div className={styles.teamSlideInfoSocials}>
                        <a href={vkLink} className={styles.teamSlideInfoSocialLink}>
                            <Vk />
                        </a>

                        <a
                            href={instagramLink}
                            className={cn(styles.teamSlideInfoSocialLink, styles.small)}
                        >
                            <Instagram />
                        </a>

                        <a
                            href={telegramLink}
                            className={cn(styles.teamSlideInfoSocialLink, styles.small)}
                        >
                            <Telegram2 />
                        </a>
                    </div>
                </div>
            </div>

            <ModalNew active={teamModal} setActive={setTeamModal}>
                <div className={styles.modalTeamContent}>
                    <p className={cn(base.title2, styles.modalTeamTitle)}>{name}</p>

                    <img src={image} alt="team" className={styles.modalTeamImg} />

                    <p className={styles.modalTeamRole}>{role}</p>

                    <div className={styles.modalTeamSocials}>
                        <a href={vkLink} className={styles.teamSlideInfoSocialLink}>
                            <Vk />
                        </a>

                        <a href={instagramLink} className={styles.teamSlideInfoSocialLink}>
                            <Instagram />
                        </a>

                        <a href={telegramLink} className={styles.teamSlideInfoSocialLink}>
                            <Telegram2 />
                        </a>
                    </div>

                    <div className={styles.modalTeamDesc}>{children}</div>
                </div>
            </ModalNew>
        </>
    );
};

export default TeamItem;
