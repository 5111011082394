export const handleFetchCodeByType = (type,setError,code,setErrorMsg,setStep,nextStep) => {
    const accessToken = localStorage.getItem('access_token')
    if (type === 'emailVerify')
      
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_email/${code.email}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        ).then((res) => {
            if (Number(res.status) === 200) {
                setError(false);
                setErrorMsg('')
                setStep(nextStep)
            } else {
                setError(true);
                return res.json()
            }
        }).then((response) => {
            if (response && response.msg) {
                setErrorMsg(response.msg)
            }
        })

    if (type === 'phoneVerify')
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_phone/${code.phone}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        ).then((res) => {
            if (Number(res.status) === 200) {
                setError(false);
                setErrorMsg('')
                setStep(nextStep)
            } else {
                setError(true);
                return res.json()
            }
        }).then((response) => {
            if (response && response.msg) {
                setErrorMsg(response.msg)
            }
        })
}