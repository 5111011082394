import Input from '../../common/Input';
import Modal from '../../common/Modal';
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { emailRegex } from '../../pages/TokenCard/PayModal/PayModal';
import styles from './modal.module.css';
import { useChangeUserDataMutation } from '../../redux/api/userService';
import { useDispatch, useSelector } from 'react-redux';
import { setIsWelcomeModalActive } from '../../redux/slices/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { SendCodeButton } from './SendCodeButton';
import { handleFetchCodeByType } from './utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const CONTACTS_VERIFY_STEPS = [
    'initialModalState',
    'emailCodeVerifaction',
    'phoneNumberVerifaction',
    'successedVerifycation',
];

const MODAL_TEXT_CONTENT = {
    initialModalState: {
        title: 'Пожалуйста укажите свои контакты',
        type: 'default',
    },

    emailCodeVerifaction: {
        title: 'Пожалуйста подтвердите важу почту',
        type: 'emailVerify',
    },

    phoneNumberVerifaction: {
        title: 'Пожалуйста подтвердите свой телефон',
        type: 'phoneVerify',
    },

    successedVerifycation: {
        title: 'Верификация прошла успешно!',
        type: 'success',
    },
};

export const ContactsModal = ({ setIsConfirmedEmail }) => {
    const [email, setEmail] = useState('');
    // const [telegram, setTelegram] = useState('');
    const [phone, setPhone] = useState('');
    // const [isClicked, setIsClicked] = useState(false);
    const [verifyStep, setVerifyStep] = useState(0);
    const [code, setCode] = useState({
        email: '',
        phone: '',
    });
    // const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isWelcomeModalActive = useSelector((state) => state.auth.isWelcomeModalActive);
    const [userProfile, setUserProfile] = useState(null);
    const isRendered = useRef(true);
    const userName = useSelector((state) => state.auth.username);
    const [changeData] = useChangeUserDataMutation();
    const [errorEmailCode, setErrorEmailCode] = useState(true);
    const [errorPhoneCode, setErrorPhoneCode] = useState(true);
    const [errorEmailUpdate, setErrorEmailUpdate] = useState(true);
    const [errorPhoneUpdate, setErrorPhoneUpdate] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const currentStep = useMemo(() => {
        return CONTACTS_VERIFY_STEPS[verifyStep];
    }, [verifyStep]);

    const handleCloseModal = () => {
        setIsConfirmedEmail(false);
        dispatch(setIsWelcomeModalActive(false));
    };

    const handleNextStep = useCallback(
        (type) => {
            if (userProfile) {
                const { email_verified, phone_verified } = userProfile;
                if (verifyStep === 0) {
                    if (phone !== userProfile.phone) {
                        setVerifyStep(2);
                    }
                    if (email !== userProfile.email) {
                        setVerifyStep(1);
                    }
                    if (email_verified && !phone_verified) {
                        setVerifyStep(2);
                    }
                    if (phone_verified && !email_verified) {
                        setVerifyStep(1);
                    }
                    if (!phone_verified && !email_verified) {
                        setVerifyStep(1);
                    }
                }
                if (verifyStep === 1) {
                    handleFetchCodeByType(
                        type,
                        setErrorEmailCode,
                        code,
                        setErrorMsg,
                        setVerifyStep,
                        phone_verified ? 3 : 2,
                    );
                }
                if (verifyStep === 2) {
                    handleFetchCodeByType(
                        type,
                        setErrorPhoneCode,
                        code,
                        setErrorMsg,
                        setVerifyStep,
                        3,
                    );
                }
            }
        },
        [code, userProfile, email, phone, verifyStep],
    );

    const handleGoBack = () => {
        if (verifyStep > 0 && verifyStep < 3) {
            setVerifyStep((prev) => prev - 1);
        }
    };

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');
        if (isWelcomeModalActive) {
            fetch('https://backend.checkbrand.site/auth_service/user/api/v1/profile/my', {
                headers: {
                    authorization: `Bearer ${access_token}`,
                },
            })
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }
                })
                .then((response) => {
                    if (response && !response.errors) {
                        setUserProfile(response);
                        if (response.phone) {
                            setPhone(response.phone);
                        }
                        if (response.email) {
                            setEmail(response.email);
                        }
                    }
                });
        }
        if (!isWelcomeModalActive) {
            setVerifyStep(0);
        }
    }, [isWelcomeModalActive]);

    if (userProfile) {
        console.log(userProfile.phone, phone);
    }

    const handleSetPhoneValue = (value) => {
        if (value.startsWith('7')) {
            setPhone(value.substring(1));
        }
    };
    return (
        userProfile && (
            <Modal
                active={isWelcomeModalActive}
                title={'Контакты'}
                onClose={handleCloseModal}
                type={'contacts'}
                goPrevStep={() => handleGoBack()}
            >
                {verifyStep === 0 && <p className={styles.welcome}>CheckBrand приветствует Вас!</p>}
                <p style={{ margin: '20px 0 40px 0', color: 'white' }}>
                    {MODAL_TEXT_CONTENT[currentStep].title}
                </p>

                {verifyStep === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        {userProfile.email === email && userProfile.email_verified ? (
                            <span style={{ color: '#48ab48' }}>подтверждено</span>
                        ) : (
                            <span style={{ color: 'red' }}>не подтверждено</span>
                        )}
                        <Input
                            placeholder="Электронная почта*"
                            value={email}
                            setValue={setEmail}
                            className={styles.input}
                        />
                        {userProfile.phone === phone && userProfile.phone_verified ? (
                            <span style={{ color: '#48ab48' }}>подтверждено</span>
                        ) : (
                            <span style={{ color: 'red' }}>не подтверждено</span>
                        )}
                        <InputMask
                            maskChar={null}
                            mask="79999999999"
                            placeholder={'Телефон'}
                            className={`${styles.input} input create__item--input `}
                            value={phone}
                            onChange={(e) => {
                                handleSetPhoneValue(e.target.value);
                            }}
                        />
                        <p className={styles.text}>*Обязательное поле</p>
                    </div>
                )}
                {verifyStep === 1 && (
                    <div style={{ width: '65%', gap: '10px' }}>
                        <p className={styles.text}>почта {email}</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                className="input create__item--input "
                                placeholder="Введите 6-и значный код"
                                value={code.email}
                                onChange={(e) =>
                                    setCode((prev) => ({ ...prev, email: e.target.value }))
                                }
                                isError={code.email.length === 0}
                                style={{ width: '100%', margin: '5px' }}
                            />

                            <SendCodeButton
                                type={'email'}
                                data={email}
                                codeError={errorEmailCode}
                                updateError={errorEmailUpdate}
                                setUpdateError={setErrorEmailUpdate}
                            />
                        </div>
                        <span style={{ color: 'red' }}>{errorMsg}</span>
                    </div>
                )}
                {verifyStep === 2 && (
                    <div style={{ width: '65%', gap: '10px' }}>
                        <p className={styles.text}>телефон {phone}</p>
                        <div>
                            <input
                                className="input create__item--input "
                                placeholder="Введите 6-и значный код"
                                value={code.phone}
                                onChange={(e) =>
                                    setCode((prev) => ({ ...prev, phone: e.target.value }))
                                }
                                isError={code.length === 0}
                                style={{ width: '100%', margin: '5px' }}
                            />
                            <SendCodeButton
                                type={'phone'}
                                data={phone}
                                codeError={errorPhoneCode}
                                updateError={errorPhoneUpdate}
                                setUpdateError={setErrorPhoneUpdate}
                            />
                        </div>
                        <span style={{ color: 'red' }}>{errorMsg}</span>
                    </div>
                )}
                <p style={{ color: 'red' }}>
                    {errorMsg && errorMsg.email_otp && errorMsg.email_otp[0]}
                </p>
                {verifyStep === 3 ? (
                    <button
                        className={`button token__card--content--price--buy ${styles.btn}`}
                        onClick={() => handleCloseModal()}
                        style={{ height: '52px' }}
                    >
                        {verifyStep < 3 ? 'продолжить' : 'готово'}
                    </button>
                ) : (
                    <button
                        className={`button token__card--content--price--buy ${styles.btn}`}
                        onClick={() => handleNextStep(MODAL_TEXT_CONTENT[currentStep].type)}
                        style={{ height: '52px' }}
                    >
                        {verifyStep < 3 ? 'продолжить' : 'готово'}
                    </button>
                )}
            </Modal>
        )
    );
};

// && !userProfile.phone_verified
// }
// if (verifyStep === 1 && userProfile.phone_verified) {
// handleFetchCodeByType(
//     type,
//     setErrorEmailCode,
//     code,
//     setErrorMsg,
//     setVerifyStep,
//     3,
// );
