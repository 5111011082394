exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TablePaginationActions_container__9KufT {\n    display: flex;\n    width: 290px;\n    min-width: 290px;\n    margin-left: 40px;\n    justify-content: flex-end;\n    flex-wrap: nowrap;\n}\n\n.TablePaginationActions_navList__1hD88 {\n    flex-wrap: nowrap;\n}\n.TablePaginationActions_navList__1hD88 svg {\n    width: 16px !important;\n    height: 16px !important;\n}\n\n.TablePaginationActions_page__3QltO {\n    width: 36px;\n    height: 36px;\n    color: white !important;\n    font-size: 16px !important;\n    font-family: 'Gilroy', sans-serif !important;\n    font-weight: 400 !important;\n}\n\n.TablePaginationActions_button__2QDe4 {\n    background-color: white !important;\n    cursor: pointer;\n}\n\n.TablePaginationActions_button__2QDe4:hover {\n    background: rgba(255, 255, 255, 0.665) !important;\n}\n\n.TablePaginationActions_selected__3eCIO {\n    font-weight: 500 !important;\n    color: #fec83d !important;\n    background-color: transparent;\n}\n", ""]);

// exports
exports.locals = {
	"container": "TablePaginationActions_container__9KufT",
	"navList": "TablePaginationActions_navList__1hD88",
	"page": "TablePaginationActions_page__3QltO",
	"button": "TablePaginationActions_button__2QDe4",
	"selected": "TablePaginationActions_selected__3eCIO"
};