const accessToken = localStorage.getItem('access_token');
export const selectVariants = [
    {
        name: 'Админ',
        title: 'Admin',
        value: 2,
    },
    {
        name: 'Модератор магазина',
        title: 'Moderator Store',
        value: 9,
    },
    {
        name: 'Модератор фабрики',
        title: 'Moderator Fabric',
        value: 10,
    },
    {
        name: 'Модератор',
        title: 'Moderator',
        value: 15,
    },
];

export const getRoleNameByStringTitle = (roleName) => {
    const foundedRole = selectVariants.find((role) => role.title === roleName);
    if (foundedRole) {
        return foundedRole.value;
    }
    if (!foundedRole) {
        return '';
    }
};

export const createAdmin = (setReqStatus, wallet, description, status, accessId) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            access: [
                {
                    resource_id: accessId.resource_id,
                    sub_resource_id: accessId.sub_resource_id,
                },
            ],
            public_address: wallet,
            role_number: status,
            description: description,
        }),
    }).then((res) => {
        if (Number(res.status) <= 250) {
            setReqStatus({ text: 'Передача токена отменена!', status: 200 });
        }
        if (Number(res.status) >= 400) {
            // setReqStatus({ text: 'При отправке запроса произошла ошибка', status: 500 });
            return res.json()
        }
    }).then((response) => {
        if(response.data) {
            setReqStatus({ text: `При отправке запроса произошла ошибка: ${response.data}`, status: 500 });
        }
        
    })
};

export const editRole = (id, accessId, deleteAccesList, status, setReqStatus) => {
    const accessToken = localStorage.getItem('access_token');

    const requestPayload = {};

    if (status) {
        requestPayload.role_number = status;
    }

    if (accessId.resource_id) {
        requestPayload.access = [accessId];
    }
    if (deleteAccesList.length > 0) {
        requestPayload.remove_access = deleteAccesList.map((role) => {
            return role.id;
        });
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/${id}`, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
    }).then((res) => {
        if (Number(res.status) <= 250) {
            setReqStatus({ text: 'Роль изменена!', status: 200 });
            // window.location.reload()
        }
        if (Number(res.status) >= 400) {
            setReqStatus({ text: 'При отправке запроса произошла ошибка', status: 500 });
        }
    });
};

export const fetchCollections = (accessId, setCollections) => {
    fetch(
        `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/?account_id=${accessId.resource_id}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        },
    )
        .then((res) => {
            if (Number(res.status) <= 210) {
                return res.json();
            }
            if (Number(res.status) >= 400) {
                return new Error('');
            }
        })
        .then((response) => {
            if (response && response.results && response.results.length > 0) {
                setCollections(
                    response.results.map((brand) => {
                        return { value: brand.id, name: brand.name, state: false };
                    }),
                );
            }
            if (response && response.results && response.results.length === 0) {
                setCollections([]);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const fetchUserInfoById = (setUserAccess, roleId, setStatus) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/${roleId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    })
        .then((res) => {
            if (Number(res.status) === 200) {
                return res.json();
            }
            if (Number(res.status) >= 400) {
                return new Error(res.json());
            }
        })
        .then((response) => {
            setUserAccess(response);
            setStatus(getRoleNameByStringTitle(response.role.public_name));
        })
        .catch((err) => {
            console.log(err);
        });
};

export const handleChangeDeleteRolesList = (setDeleteList, payload, isDelete) => {
    setDeleteList((prev) => {
        if (isDelete) {
            return prev.filter(
                (role) =>
                    role.resource_id !== payload.resource_id ||
                    role.sub_resource_id !== payload.sub_resource_id,
            );
        }
        return prev.some(
            (item) =>
                item.resource_id === payload.resource_id &&
                item.sub_resource_id === payload.sub_resource_id,
        )
            ? prev
            : [...prev, payload];
    });
};

export const findRole = (rolesDeleteList, access) => {
    return rolesDeleteList.find(
        (el) =>
            el.resource_id === access.resource_id && el.sub_resource_id === access.sub_resource_id,
    )
        ? true
        : false;
};

export const handleSelectId = (setAccessId, setFilterData, brand, type) => {
    // console.log(type);
    setAccessId((prev) => ({ ...prev, [type]: brand.value }));
    setFilterData((prev) => {
        return prev.map((checkbox) => {
            if (checkbox.name === brand.name) {
                return { ...checkbox, state: !brand.state };
            }
            return { ...checkbox, state: false };
        });
    });
};
