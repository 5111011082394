exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tooltip_container__aZS-r {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.Tooltip_tooltip__31DP7 {\n    left: 10px;\n    font-family: 'Gilroy', sans-serif;\n    font-size: 14px !important;\n    font-weight: 400;\n    line-height: 20px !important;\n    background-color: #515151 !important;\n    border-radius: 8px;\n}\n\n.Tooltip_arrow__tc23i {\n    position: absolute !important;\n    top: 7px !important;\n    right: calc(50% - 4px) !important;\n    color: #101010 !important;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Tooltip_container__aZS-r",
	"tooltip": "Tooltip_tooltip__31DP7",
	"arrow": "Tooltip_arrow__tc23i"
};