exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Statistics_headerText__lLihT {\n    color: #fff;\n    font-weight: 600;\n    text-align: center;\n    font-size: 18px;\n}\n\n.Statistics_buttonsCell__14uMf {\n    display: flex;\n    justify-content: space-between;\n    grid-gap: 8px;\n    gap: 8px;\n}\n\n.Statistics_trashIconBox__OMpJY,\n.Statistics_editIconBox__1au00 {\n    width: 36px;\n    height: 36px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    background-color: white;\n    cursor: pointer;\n    transition: background-color 200ms ease-in-out;\n}\n\n.Statistics_trashIconBox__OMpJY:hover {\n    background-color: rgba(255, 255, 255, 0.665);\n}\n.Statistics_editIconBox__1au00:hover {\n    background-color: rgba(255, 255, 255, 0.665);\n}\n\n.Statistics_trashIcon__2tQU-,\n.Statistics_editIcon__45bP0 {\n    width: 24px !important;\n    height: 24px !important;\n    color: black;\n}\n\n.Statistics_notFoundContainer__ldwiJ {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.Statistics_beforeTableElement__3aRlw {\n    height: 16px;\n    width: 100%;\n}\n\n.Statistics_imageRoot__BIROB {\n    width: 36px;\n    height: 36px;\n    border-radius: 4px;\n    background: rgba(229, 232, 235, 0.04);\n    margin-right: 8px;\n}\n\n.Statistics_cellRoot__3sM-P {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.Statistics_notFoundContainer__ldwiJ {\n    font-size: 16px;\n    color: #fff;\n    font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"headerText": "Statistics_headerText__lLihT",
	"buttonsCell": "Statistics_buttonsCell__14uMf",
	"trashIconBox": "Statistics_trashIconBox__OMpJY",
	"editIconBox": "Statistics_editIconBox__1au00",
	"trashIcon": "Statistics_trashIcon__2tQU-",
	"editIcon": "Statistics_editIcon__45bP0",
	"notFoundContainer": "Statistics_notFoundContainer__ldwiJ",
	"beforeTableElement": "Statistics_beforeTableElement__3aRlw",
	"imageRoot": "Statistics_imageRoot__BIROB",
	"cellRoot": "Statistics_cellRoot__3sM-P"
};